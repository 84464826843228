import React, { useRef } from "react";
import "./Version.css";
import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import Table from "../Table/Table";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import Android from "../../Images/android-logo-sdk.png";
import iOS from "../../Images/Apple_logo_black.svg.png";
import Constants from "../../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faBook,
  faPlus,
  faKey,
  faEllipsisH,
  faWaveSquare,
  faCopy,
  faTrash,
  faPen,
  faArrowCircleRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Empty from "../Empty/Empty";
import DocBlock from "../DocBlock/DocBlock";
import SkeletonLoader from "../Skeleton/SkeletonLoader";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import AppTooltip from "../AppTooltip/AppTooltip";
import { ExportToCsv } from "export-to-csv";

const Version = () => {
  const params = useParams();
  const [versions, setVersions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [sort, setSort] = useState("_id:desc");
  const [forceUpgradeFilter, setForceUpgradeFilter] = useState(null);
  const [totalPages, setTotalPages] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [csvLoading, setCsvLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [versionsState, setVersionsState] = useState({});
  const forcePayment = localStorage.getItem("force-payment");

  const handleVersionsExport = () => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    var URL = `${Constants.URL.SERVER_API_URL}/api/v1/projects/${
      params.id
    }/versions?teamId=${localStorage.getItem(
      "current_team"
    )}&page=1&limit=100000&sortBy=${sort}`;

    if (forceUpgradeFilter) {
      if (
        forceUpgradeFilter === "active" ||
        forceUpgradeFilter === "inactive"
      ) {
        URL = URL + `&active=${forceUpgradeFilter === "active" ? true : false}`;
      } else {
        URL = URL + `&forceUpgrade=${forceUpgradeFilter}`;
      }
    }

    setCsvLoading(true);
    fetch(URL, options)
      .then((res) => {
        if (res.status === 200) {
          res.json().then((versions) => {
            const options = {
              showLabels: true,
              useKeysAsHeaders: true,
            };
            const csvExporter = new ExportToCsv(options);

            csvExporter.generateCsv(convertData(versions.results));
          });
          setCsvLoading(false);
        } else if (res.status >= 400 && res.status < 500) {
          res.json().then((json) => {
            toast.error(json.message);
            setCsvLoading(false);
          });
        } else {
          res.json().then((json) => {
            toast.error(json.message);
            setCsvLoading(false);
          });
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setCsvLoading(false);
      });
  };

  function convertData(data) {
    return data.map((item) => ({
      versionId: item.id,
      project: item.project.name,
      projectId: item.project.id,
      appName: item.appName,
      appVersion: item.appVersion,
      platform: item.platform,
      environment: item.environment,
      updateMessage: item.message,
      status: item.active === true ? "Yes" : "No",
      internalNotes: item.internalNotes,
      forceUpgrade: item.forceUpgrade,
      ...item.customAttributes,
      createdBy: item.createdBy,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      ...item.messageLocale.reduce((acc, locale, index) => {
        acc[`message_locale_${locale.key}`] = locale.value;
        return acc;
      }, {}),
    }));
  }

  const handleDelete = (version) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "shadow",
        cancelButton: "shadow",
      },
      buttonsStyling: true,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          // Delete call here.
          deleteVersion(
            params,
            currentPage,
            limit,
            sort,
            forceUpgradeFilter,
            setTotalPages,
            versions,
            setVersions,
            setVersionsState,
            version,
            setIsLoading,
            setIsRefreshing,
            setApiError
          );
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Version has been deleted.",
            "success"
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Action is cancelled. :)",
            "error"
          );
        }
      });
  };

  const handlePrev = () => {
    setCurrentPage(currentPage - 1);
    getVersions(
      params,
      currentPage - 1,
      limit,
      sort,
      forceUpgradeFilter,
      setTotalPages,
      versions,
      setVersions,
      setVersionsState,
      setIsLoading,
      setIsRefreshing,
      setApiError
    );
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    getVersions(
      params,
      currentPage + 1,
      limit,
      sort,
      forceUpgradeFilter,
      setTotalPages,
      versions,
      setVersions,
      setVersionsState,
      setIsLoading,
      setIsRefreshing,
      setApiError
    );
  };

  const handleSort = (event) => {
    setSort(event.target.value);
    getVersions(
      params,
      currentPage,
      limit,
      event.target.value,
      forceUpgradeFilter,
      setTotalPages,
      versions,
      setVersions,
      setVersionsState,
      setIsLoading,
      setIsRefreshing,
      setApiError
    );
  };

  const handleForceUpgradeFilter = (event) => {
    setForceUpgradeFilter(event.target.value);
    getVersions(
      params,
      currentPage,
      limit,
      sort,
      event.target.value,
      setTotalPages,
      versions,
      setVersions,
      setVersionsState,
      setIsLoading,
      setIsRefreshing,
      setApiError
    );
  };

  const handleStatusUpdate = (version) => {
    updateVersionStatus(params, version, handleVersionsStateChange);
  };

  const handleVersionsStateChange = (id) => {
    setVersionsState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const columns = useMemo(() => [
    {
      Header: "Project",
      accessor: "project.name",
      Cell: ({ row }) => {
        const data = row.original;
        return (
          <>
            {isRefreshing ? (
              <SkeletonLoader />
            ) : (
              <AppTooltip placement="bottom" overlay={data.project.name}>
                <span className="overflow">{data.project.name}</span>
              </AppTooltip>
            )}
          </>
        );
      },
    },
    {
      Header: "App Name",
      accessor: "appName",
      Cell: ({ row }) => {
        const data = row.original;
        return (
          <div className="d-flex flex-column align-items-start">
            {isRefreshing ? <SkeletonLoader /> : data.appName}
            <span style={{ fontSize: "8px" }} className="">
              {new Date(data.createdAt).toDateString()}
            </span>
          </div>
        );
      },
    },
    {
      Header: "App Version",
      accessor: "appVersion",
      Cell: ({ row }) => {
        const data = row.original;
        return (
          <>
            {isRefreshing ? (
              <SkeletonLoader />
            ) : (
              <div className="badge version">
                <p>{data.appVersion}</p>
              </div>
            )}
          </>
        );
      },
    },
    {
      Header: "Platform",
      accessor: "platform",
      Cell: ({ row }) => {
        const data = row.original;
        return isRefreshing ? (
          <SkeletonLoader />
        ) : data.platform === "android" || data.platform === "Android" ? (
          <div
            className="d-flex justify-content: center"
            style={{ justifyContent: "center" }}
          >
            <img
              className="platform-logo-android"
              src={Android}
              alt="android"
            />{" "}
            <p style={{ paddingLeft: "10px" }}></p>
          </div>
        ) : data.platform === "iOS" || data.platform === "ios" ? (
          <div
            className="d-flex justify-content: center"
            style={{ justifyContent: "center" }}
          >
            <img className="platform-logo-ios" src={iOS} alt="iOS" />{" "}
            <p style={{ paddingLeft: "10px" }}></p>
          </div>
        ) : (
          <div
            className="d-flex justify-content: center"
            style={{ justifyContent: "center" }}
          >
            <p style={{ paddingLeft: "5px" }}>{data.platform}</p>
          </div>
        );
      },
    },
    {
      Header: "Environment",
      accessor: "environment",
      Cell: ({ row }) => {
        const data = row.original;
        return <>{isRefreshing ? <SkeletonLoader /> : data.environment}</>;
      },
    },
    {
      Header: "Force Upgrade",
      tipText:
        "If value is Yes than the version will be force updated. User will see a non-dismissable popup.",
      accessor: (originalRow) => {
        return (
          <>
            {isRefreshing ? (
              <SkeletonLoader />
            ) : originalRow.forceUpgrade === "Yes" ? (
              <div className="badge upgrade-toggle">
                <p>{originalRow.forceUpgrade}</p>
              </div>
            ) : (
              <div className="badge version">
                <p>{originalRow.forceUpgrade}</p>
              </div>
            )}
          </>
        );
      },
    },
    {
      Header: "",
      accessor: (originalRow, rowIndex) =>
        isRefreshing ? (
          <SkeletonLoader />
        ) : originalRow.customAttributes &&
          Object.keys(originalRow.customAttributes).length > 0 ? (
          <div style={{ display: "flex" }}>
            <AppTooltip
              placement="bottom"
              overlay={Object.keys(originalRow.customAttributes)
                .map((key) => `${key}: ${originalRow.customAttributes[key]}`)
                .join(", ")}
            >
              <div>
                <div
                  style={{ borderRadius: "100px" }}
                  className="d-flex justify-content-center align-items-center font-weight-medium px-2 text-gray-300 border border-dark"
                >
                  <b>{Object.keys(originalRow.customAttributes).length}</b>
                  <span style={{ fontSize: "10px" }} className="px-1">
                    {" "}
                    custom attribute(s)
                  </span>
                </div>
              </div>
            </AppTooltip>
          </div>
        ) : (
          <></>
        ),
      id: "customAttributes",
    },
    {
      Header: "Status",
      tipText:
        "If the version is active it will be matched for update. If the version is in inactive state it won't be matched and the update prompt won't be shown in the app. You can toggle the status of version from below checkbox.",
      accessor: (originalRow, rowIndex) =>
        isRefreshing ? (
          <SkeletonLoader />
        ) : (
          <label htmlFor={originalRow.id} className="status-switch">
            <input
              type="checkbox"
              id={originalRow.id}
              checked={versionsState[originalRow.id] || false}
              onChange={(e) => {
                handleVersionsStateChange(originalRow.id);
                originalRow.active = !originalRow.active;
                handleStatusUpdate(originalRow);
              }}
            />
            <span></span>
          </label>
        ),
      id: "state",
    },
    {
      Header: "",
      accessor: (originalRow, rowIndex) =>
        isRefreshing ? (
          <SkeletonLoader />
        ) : (
          <Tippy
            className="tippy-container"
            trigger="click"
            placement="left"
            arrowType="round"
            animation="scale"
            theme="light"
            distance="12"
            interactive="true"
            content={
              <nav>
                <Link
                  to={
                    "/projects/" +
                    params.id +
                    "/versions/" +
                    originalRow.id +
                    "/edit"
                  }
                  className="menu-link"
                >
                  <FontAwesomeIcon className="fa-light mr-2" icon={faPen} />
                  <span>Edit</span>
                </Link>
                <Link
                  to={
                    "/projects/" +
                    params.id +
                    "/versions/" +
                    originalRow.id +
                    "/duplicate"
                  }
                  className="menu-link"
                >
                  <FontAwesomeIcon className="fa-light mr-2" icon={faCopy} />
                  <span>Clone</span>
                </Link>
                <Link
                  to={"/projects/" + params.id + "/versions/" + originalRow.id}
                  className="menu-link"
                >
                  <FontAwesomeIcon
                    className="fa-light mr-2"
                    icon={faArrowCircleRight}
                  />
                  <span>Details</span>
                </Link>
                <Link
                  to={
                    "/projects/" +
                    params.id +
                    "/versions/" +
                    originalRow.id +
                    "/events"
                  }
                  className="menu-link"
                >
                  <FontAwesomeIcon
                    className="fa-light mr-2"
                    icon={faWaveSquare}
                  />
                  <span>Events</span>
                </Link>
                <span
                  onClick={() => handleDelete(originalRow)}
                  className="menu-link menu-link-danger"
                >
                  <FontAwesomeIcon className="fa-light mr-2" icon={faTrash} />
                  <span>Delete</span>
                </span>
              </nav>
            }
          >
            <h6 className="text-nav dot-menu" style={{ cursor: "pointer" }}>
              <FontAwesomeIcon className="fa-solid" icon={faEllipsisH} />
            </h6>
          </Tippy>
        ),
      id: "action",
    },
  ]);

  useEffect(() => {
    getVersions(
      params,
      currentPage,
      limit,
      sort,
      forceUpgradeFilter,
      setTotalPages,
      versions,
      setVersions,
      setVersionsState,
      setIsLoading,
      setIsRefreshing,
      setApiError
    );
  }, []);

  let component;

  const skeleton = (
    <div className="table-responsive">
      <table role="table" className="shadow table table-hover">
        <thead>
          <tr role="row">
            <th colSpan="1" role="columnheader">
              <SkeletonLoader />
            </th>
            <th colSpan="1" role="columnheader">
              <SkeletonLoader />
            </th>
            <th colSpan="1" role="columnheader">
              <SkeletonLoader />
            </th>
            <th colSpan="1" role="columnheader">
              <SkeletonLoader />
            </th>
            <th colSpan="1" role="columnheader">
              <SkeletonLoader />
            </th>
            <th colSpan="1" role="columnheader">
              <SkeletonLoader />
            </th>
            <th colSpan="1" role="columnheader">
              <SkeletonLoader />
            </th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <tr role="row">
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
          </tr>
          <tr role="row">
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
          </tr>
          <tr role="row">
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  if (isLoading) {
    component = skeleton;
  } else if (apiError) {
    component = <Empty text={apiError} />;
  } else if (versions.length > 0) {
    component = (
      <>
        {/* <div className="my-2" style={{ textAlign: "left", fontSize: "12px" }}>
          <span className="text-cool-gray-600 mb-4">
            💡You can see the number of times these versions matched for update
            by clicking on <q>Details</q>.
          </span>
        </div> */}
        <Table columns={columns} data={versions} />
        {totalPages > 1 ? (
          <div className="d-flex flex-row-reverse pagination-container">
            <Button
              className="shadow purple-button"
              variant="primary"
              disabled={currentPage === totalPages}
              type="submit"
              onClick={() => handleNext()}
            >
              Next
            </Button>
            <Button
              style={{ marginRight: "5px" }}
              className="shadow purple-button"
              variant="primary"
              disabled={currentPage === 1}
              type="submit"
              onClick={() => handlePrev()}
            >
              Prev
            </Button>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  } else {
    component = (
      <Empty text="You have not created any version yet. Create one and it will show up here." />
    );
  }

  return (
    <section
      className="project-area my-5"
      style={
        forcePayment === "true"
          ? { filter: "blur(8px)", pointerEvents: "none" }
          : {}
      }
    >
      <div className="container">
        <div className="d-flex justify-content-between intro-container">
          <div className="version-heading">
            <div className="d-flex justify-content">
              <div>
                <p>
                  <Link className="gradient-text-accent" to={"/projects"}>
                    Projects
                  </Link>{" "}
                  <FontAwesomeIcon className="fa-solid" icon={faAngleRight} />{" "}
                  Versions{" "}
                </p>
              </div>
            </div>
            <span>Manage versions of your app.</span>
          </div>
          <Link to={"/projects/" + params.id + "/versions/create"}>
            <Button
              variant="primary"
              type="submit"
              className="new-version-button shadow gradient-bg-accent font-weight-bold"
            >
              <FontAwesomeIcon className="fa-thin mr-2" icon={faPlus} />
              New Version
            </Button>
          </Link>
        </div>
        <div className="version-table">
          <div className="row mb-2">
            <div
              className="col-6 col-md-2 mr-auto mb-2"
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              <Link
                className="mb-0 mt-2"
                to={"/projects/" + params.id + "/view"}
              >
                <span className="text-purple font-weight-bold ">
                  <FontAwesomeIcon className="fa-solid mr-2" icon={faKey} />
                  x-api-key
                </span>
              </Link>
            </div>
            <div
              className="col-6 col-md-2 mr-auto mb-2"
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              <a
                className="mb-0 mt-2"
                target="_blank"
                href="https://appupgrade.dev/docs/integration-with-app-upgrade"
              >
                <span className="text-purple font-weight-bold">
                  <FontAwesomeIcon className="fa-solid mr-2" icon={faBook} />
                  Integration Doc
                </span>
              </a>
            </div>
            <div
              className="col-6 col-md-2 mr-auto mb-2"
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              <button style={{ padding: "0px !important" }}>
                {!csvLoading ? (
                  <span
                    onClick={handleVersionsExport}
                    className="text-purple font-weight-bold"
                  >
                    <FontAwesomeIcon
                      className="fa-solid mr-2"
                      icon={faDownload}
                    />
                    Export
                  </span>
                ) : (
                  <span
                    onClick={handleVersionsExport}
                    className="text-purple font-weight-bold"
                  >
                    <span
                      className="spinner-border spinner-border-sm mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Exporting..
                  </span>
                )}
              </button>
            </div>
            <div className="col-md-3 mb-2">
              <select
                className="form-control"
                aria-label="Default select example"
                onChange={(e) => handleForceUpgradeFilter(e)}
              >
                <option value="">No Filter</option>
                <option value="true">Filter: Force Upgrade = Yes</option>
                <option value="false">Filter: Force Upgrade = No</option>
                <option value="active">Filter: Status = Active</option>
                <option value="inactive">Filter: Status = Inactive</option>
              </select>
            </div>
            <div className="col-md-3 mb-2">
              <select
                className="form-control"
                aria-label="Default select example"
                onChange={(e) => handleSort(e)}
              >
                <option value="_id:desc">Sort: Newest</option>
                <option value="_id:asc">Sort: Oldest</option>
                <option value="updatedAt:desc">Sort: Recently Updated</option>
                <option value="appName:asc">Sort: App Name: A-Z</option>
                <option value="appName:desc">Sort: App Name: Z-A</option>
                <option value="platform:asc">Sort: Platform: A-Z</option>
                <option value="platform:desc">Sort: Platform: Z-A</option>
                <option value="environment:asc">Sort: Environment: A-Z</option>
                <option value="environment:desc">Sort: Environment: Z-A</option>
              </select>
            </div>
          </div>
          {component}
        </div>
        <DocBlock></DocBlock>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Version;

function getVersions(
  params,
  currentPage,
  limit,
  sort,
  forceUpgradeFilter,
  setTotalPages,
  versions,
  setVersions,
  setVersionsState,
  setIsLoading,
  setIsRefreshing,
  setApiError
) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  var URL = `${Constants.URL.SERVER_API_URL}/api/v1/projects/${
    params.id
  }/versions?teamId=${localStorage.getItem(
    "current_team"
  )}&page=${currentPage}&limit=${limit}&sortBy=${sort}`;

  if (forceUpgradeFilter) {
    if (forceUpgradeFilter === "active" || forceUpgradeFilter === "inactive") {
      URL = URL + `&active=${forceUpgradeFilter === "active" ? true : false}`;
    } else {
      URL = URL + `&forceUpgrade=${forceUpgradeFilter}`;
    }
  }

  setIsRefreshing(true);
  fetch(URL, options)
    .then((res) => {
      setIsLoading(false);
      setIsRefreshing(false);
      if (res.status === 200) {
        return res.json().then((versions) => {
          setApiError(null);
          setTotalPages(versions.totalPages);
          setVersions(versions.results);
          setVersionsState(
            versions.results.reduce(
              (result, item) => ({ ...result, [item.id]: item.active }),
              {}
            )
          );
        });
      } else if (res.status >= 400 && res.status < 500) {
        return res.json().then((json) => {
          toast.error(json.message);
          setApiError(json.message);
        });
      } else {
        return res.json().then((json) => {
          throw new Error(json.message);
        });
      }
    })
    .catch((err) => {
      setIsLoading(false);
      setIsRefreshing(false);
      toast.error(err.message);
      setApiError("Uh oh!! something is not right!");
    });
}

function updateVersionStatus(params, version, handleVersionsStateChange) {
  const newVersion = JSON.parse(JSON.stringify(version));
  delete newVersion.createdAt;
  delete newVersion.updatedAt;
  delete newVersion.project;
  delete newVersion.createdBy;
  delete newVersion.id;
  delete newVersion.disabled;
  newVersion.forceUpgrade = version.forceUpgrade === "Yes" ? true : false;
  const options = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    body: JSON.stringify(newVersion),
  };

  fetch(
    `${Constants.URL.SERVER_API_URL}/api/v1/projects/${params.id}/versions/${
      version.id
    }?teamId=${localStorage.getItem("current_team")}`,
    options
  )
    .then((res) => {
      if (res.status === 200) {
        res.json().then((json) => {
          toast.success(
            `Version status is set to ${json.active ? "Active" : "Inactive"}`
          );
        });
      } else if (res.status >= 400 && res.status < 500) {
        handleVersionsStateChange(version.id);
        return res.json().then((json) => {
          toast.error(json.message);
        });
      } else {
        handleVersionsStateChange(version.id);
        return res.json().then((json) => {
          throw new Error(json.message);
        });
      }
    })
    .catch((err) => {
      handleVersionsStateChange(version.id);
      toast.error(err.message);
    });
}

function deleteVersion(
  params,
  currentPage,
  limit,
  sort,
  forceUpgradeFilter,
  setTotalPages,
  versions,
  setVersions,
  setVersionsState,
  version,
  setIsLoading,
  setIsRefreshing,
  setApiError
) {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  fetch(
    `${Constants.URL.SERVER_API_URL}/api/v1/projects/${params.id}/versions/${
      version.id
    }?teamId=${localStorage.getItem("current_team")}`,
    options
  )
    .then((res) => {
      if (res.status === 204) {
        toast.success("Version successfully deleted.");
        getVersions(
          params,
          currentPage,
          limit,
          sort,
          forceUpgradeFilter,
          setTotalPages,
          versions,
          setVersions,
          setVersionsState,
          setIsLoading,
          setIsRefreshing,
          setApiError
        );
      } else if (res.status >= 400 && res.status < 500) {
        return res.json().then((json) => {
          toast.error(json.message);
        });
      } else {
        return res.json().then((json) => {
          throw new Error(json.message);
        });
      }
    })
    .catch((err) => {
      toast.error(err.message);
    });
}
