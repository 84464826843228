import React from "react";
import "./CreateVersion.css";
import CreateVersionForm from "../VersionForm/CreateVersionForm";

const CreateVersion = (props) => {
  return (
    <section className="project-area my-5">
      <div className="container">
        <CreateVersionForm></CreateVersionForm>
      </div>
    </section>
  );
};

export default CreateVersion;
