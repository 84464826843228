import React, { useRef, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Account.css";
import useAuth from "../SignIn/UseAuth";
import { useEffect, useState } from "react";
import Constants from "../../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faKey,
  faUserFriends,
  faCreditCard,
  faSignOutAlt,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";
import AccountDetails from "./AccountDetails";

const ProjectKeys = lazy(() => import("./ProjectKeys"));
const Member = lazy(() => import("./Member"));
const Subscription = lazy(() => import("./Subscription"));

const Account = () => {
  const auth = useAuth();
  const [user, setUser] = useState([]);
  const [team, setTeam] = useState([]);

  useEffect(() => {
    getUser(setUser, auth);
    getCurrentTeam(setTeam);
  }, []);

  return (
    <section className="project-area my-5">
      <div className="container">
        <div className="d-flex justify-content-between intro-container">
          <div className="version-heading">
            <div className="d-flex justify-content">
              <div>
                <p className="gradient-text-accent">Your Account </p>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="nav nav-tabs nav-dark" id="nav-tab" role="tablist">
            <button
              className="nav-link active"
              id="nav-account-details-tab"
              data-toggle="tab"
              data-target="#account-details"
              type="button"
              role="tab"
              aria-controls="nav-account-details"
              aria-selected="true"
            >
              <FontAwesomeIcon className="fa-light mr-2" icon={faUser} />
              Profile Details
            </button>
            <button
              className="nav-link"
              id="nav-project-keys-tab"
              data-toggle="tab"
              data-target="#project-keys"
              type="button"
              role="tab"
              aria-controls="nav-project-keys"
              aria-selected="false"
            >
              <FontAwesomeIcon className="fa-light mr-2" icon={faKey} />
              Project Keys
            </button>
            <button
              className="nav-link"
              id="nav-manage-members-tab"
              data-toggle="tab"
              data-target="#manage-members"
              type="button"
              role="tab"
              aria-controls="nav-manage-members"
              aria-selected="false"
            >
              <FontAwesomeIcon className="fa-light mr-2" icon={faUserFriends} />
              Team Management
            </button>
            <button
              className="nav-link"
              id="nav-subscription-tab"
              data-toggle="tab"
              data-target="#subscription"
              type="button"
              role="tab"
              aria-controls="nav-subscription"
              aria-selected="false"
            >
              <FontAwesomeIcon className="fa-light mr-2" icon={faCreditCard} />
              Subscription
            </button>
            <button
              className="nav-link"
              id="nav-support-tab"
              data-toggle="tab"
              data-target="#support-tab"
              type="button"
              role="tab"
              aria-controls="nav-support-tab"
              aria-selected="false"
            >
              <FontAwesomeIcon className="fa-light mr-2" icon={faHeadset} />
              Support
            </button>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="account-details"
            role="tabpanel"
            aria-labelledby="nav-account-details-tab"
          >
            <AccountDetails user={user}></AccountDetails>
          </div>
          <div
            className="tab-pane fade"
            id="project-keys"
            role="tabpanel"
            aria-labelledby="nav-project-keys-tab"
          >
            <Suspense fallback={null}>
              <ProjectKeysWithLazyLoad></ProjectKeysWithLazyLoad>
            </Suspense>
          </div>
          <div
            className="tab-pane fade"
            id="manage-members"
            role="tabpanel"
            aria-labelledby="nav-manage-members-tab"
          >
            <Suspense fallback={null}>
              <MemberWithLazyLoad user={user} team={team}></MemberWithLazyLoad>
            </Suspense>
          </div>
          <div
            className="tab-pane fade"
            id="subscription"
            role="tabpanel"
            aria-labelledby="nav-subscription-tab"
          >
            <Suspense fallback={null}>
              <SubscriptionWithLazyLoad team={team}></SubscriptionWithLazyLoad>
            </Suspense>
            <div
              className="d-flex justify-content-between intro-container"
              id="support"
            ></div>
          </div>
          <div
            className="tab-pane fade"
            id="support-tab"
            role="tabpanel"
            aria-labelledby="nav-support-tab"
          >
            <div
              className="d-flex justify-content-between intro-container"
              id="support"
            >
              <div className="version-heading">
                <div className="account-components">
                  <div>
                    <h5>
                      {" "}
                      <FontAwesomeIcon
                        className="fa-thin mr-2"
                        icon={faHeadset}
                      />
                      Support
                    </h5>
                    <span>Get help and support.</span>
                  </div>
                  <div className="version-info-box mb-0">
                    <span>
                      If you have any query or need support feel free to drop us
                      an email at
                    </span>
                    <span>
                      <a
                        target="_blank"
                        href="mailto:support@appupgrade.dev"
                        className="delete-button gradient-text-accent font-weight-bold"
                      >
                        {" "}
                        support@appupgrade.dev
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </section>
  );
};

// Wrapping the lazy-loaded components with IntersectionObserver
const ProjectKeysWithLazyLoad = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return isVisible ? <ProjectKeys /> : <div ref={ref}></div>;
};

const MemberWithLazyLoad = ({ user, team }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return isVisible ? <Member user={user} team={team} /> : <div ref={ref}></div>;
};

const SubscriptionWithLazyLoad = ({ team }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return isVisible ? <Subscription team={team} /> : <div ref={ref}></div>;
};

export default Account;

function getUser(setUser, auth) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  fetch(`${Constants.URL.SERVER_API_URL}/api/v1/users/me`, options)
    .then((res) => {
      if (res.status === 200) {
        return res.json().then((user) => {
          setUser(user);
        });
      } else {
        return res.json().then((json) => {
          toast.error(json.message);
        });
      }
    })
    .catch((err) => {
      toast.error("Something went wrong!");
    });
}

function getCurrentTeam(setTeam) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  fetch(
    `${Constants.URL.SERVER_API_URL}/api/v1/teams/${localStorage.getItem(
      "current_team"
    )}`,
    options
  )
    .then((res) => {
      if (res.status === 200) {
        return res.json().then((team) => {
          setTeam(team);
        });
      } else {
        return res.json().then((json) => {
          toast.error(json.message);
        });
      }
    })
    .catch((err) => {
      toast.error("Something went wrong!");
    });
}
