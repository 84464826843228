import React from "react";
import "./Projects.css";
import ProjectItem from "../ProjectItem/ProjectItem";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Constants from "../../Constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Empty from "../Empty/Empty";
import SkeletonLoader from "../Skeleton/SkeletonLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFolderOpen } from "@fortawesome/free-solid-svg-icons";

const Projects = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [apiError, setApiError] = useState(null);
  const forcePayment = localStorage.getItem("force-payment");

  const removeProject = (id) => {
    const updatedProjects = projects.filter(item => item.id !== id);
    setProjects(updatedProjects);
  };

  useEffect(() => {
    const teamId = props.teamId || localStorage.getItem("current_team");
    if (!teamId) {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      fetch(`${Constants.URL.SERVER_API_URL}/api/v1/teams`, options)
        .then((res) => {
          if (res.status === 200) {
            return res.json().then((teams) => {
              localStorage.setItem("current_team", teams[0].id);

              const options = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              };
              fetch(
                `${Constants.URL.SERVER_API_URL}/api/v1/projects?teamId=${teams[0].id}`,
                options
              )
                .then((res) => {
                  setIsLoading(false);
                  if (res.status === 200) {
                    return res.json().then((projects) => {
                      setProjects(projects.results);
                    });
                  } else if (res.status >= 400 && res.status < 500) {
                    return res.json().then((json) => {
                      toast.error(json.message);
                      setApiError(json.message);
                    });
                  } else {
                    return res.json().then((json) => {
                      throw new Error(json.message);
                    });
                  }
                })
                .catch((err) => {
                  setIsLoading(false);
                  toast.error(err.message);
                  setApiError("Uh oh!! something is not right!");
                });
            });
          } else if (res.status >= 400 && res.status < 500) {
            return res.json().then((json) => {
              toast.error(json.message);
              setApiError(json.message);
            });
          } else {
            return res.json().then((json) => {
              throw new Error(json.message);
            });
          }
        })
        .catch((err) => {
          setApiError("Uh oh!! something is not right!");
        });
    } else {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      fetch(
        `${Constants.URL.SERVER_API_URL}/api/v1/projects?teamId=${teamId}`,
        options
      )
        .then((res) => {
          setIsLoading(false);
          if (res.status === 200) {
            return res.json().then((projects) => {
              setProjects(projects.results);
            });
          } else if (res.status >= 400 && res.status < 500) {
            return res.json().then((json) => {
              toast.error(json.message);
              setApiError(json.message);
            });
          } else {
            return res.json().then((json) => {
              throw new Error(json.message);
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.message);
          setApiError("Uh oh!! something is not right!");
        });
    }
  }, []);

  let component;

  const skeleton = (i) => (
    <div className="col-md-4 mb-4" key={i}>
      <div
        className="price-card text-center shadow project-card"
        style={{ borderRadius: "1.25rem" }}
      >
        <div className="card-body">
          <h5>
            <SkeletonLoader />
          </h5>
          <p>
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
          </p>
          <SkeletonLoader width={150} height={40} />
          <SkeletonLoader width={150} height={40} />
        </div>
      </div>
    </div>
  );

  if (isLoading) {
    const array = [1, 2, 3];
    component = (
      <div className="row">{array.map((e, i) => skeleton(i))}</div>
    );
  } else if (apiError) {
    component = <Empty text={apiError} />;
  } else if (projects.length > 0) {
    component = (
      <div className="row">
        {projects.map((project) => (
          <ProjectItem project={project} key={project.id} removeProject={removeProject} />
        ))}
      </div>
    );
  } else {
    component = (
      <Empty text="You have not created any project yet. Create one and it will show up here." />
    );
  }

  return (
    <section
      className="project-area my-5"
      style={
        forcePayment === "true"
          ? { filter: "blur(8px)", pointerEvents: "none" }
          : {}
      }
    >
      <div className="container">
        <div className="d-flex justify-content-between intro-container">
          <div className="project-heading">
            <p>
              <FontAwesomeIcon className="fa-thin mr-2" icon={faFolderOpen} />
              Projects
            </p>
            <span>Your Projects.</span>
          </div>
          <Link to={"/projects/create"}>
            <Button
              variant="primary"
              type="submit"
              className="new-project-button shadow gradient-bg-accent font-weight-bold"
            >
              <FontAwesomeIcon className="fa-thin mr-2" icon={faPlus} />
              New Project
            </Button>
          </Link>
        </div>
        {component}
      </div>
      <ToastContainer />
    </section>
  );
};

export default Projects;
