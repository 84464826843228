import React from "react";
import "./CreateProject.css";
import ViewProjectForm from "../ProjectForm/ViewProjectForm";

const ViewProject = (props) => {
  return (
    <section className="project-area my-5">
      <div className="container">
        <ViewProjectForm></ViewProjectForm>
      </div>
    </section>
  );
};

export default ViewProject;
