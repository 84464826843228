import React, { useState } from "react";
import "./ProjectForm.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Constants from "../../Constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const ProjectForm = (props) => {
  const navigate = useNavigate();
  const [apiError, setApiError] = useState(null);
  const { register, handleSubmit, errors, formState: { isSubmitting, isDirty, isValid } } = useForm({ mode: "onChange" });

  const onSubmit = (data) => {
    if (data.name && data.description) {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          name: data.name,
          description: data.description,
        }),
      };
      fetch(
        `${
          Constants.URL.SERVER_API_URL
        }/api/v1/projects?teamId=${localStorage.getItem("current_team")}`,
        options
      )
        .then((res) => {
          if (res.status === 201) {
            toast.success("Project created successfully.");
            navigate("/projects");
          } else {
            return res.json().then((json) => {
              toast.error(json.message);
              setApiError(json.message);
            });
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };

  return (
    <section className="project-area my-5">
      <div className="container">
        <div className="d-flex justify-content-between intro-container">
          <div className="version-heading">
            <div className="d-flex justify-content">
              <div>
                <p>
                  <Link className="gradient-text-accent" to={"/projects"}>
                    Projects
                  </Link>{" "}
                  <FontAwesomeIcon className="fa-solid" icon={faAngleRight} />{" "}
                  Create
                </p>
              </div>
            </div>
            <span>Create a new project.</span>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="form-group">
            <input
              name="name"
              className="form-control"
              ref={register({ required: true })}
              placeholder="*Name"
            />
            {errors.name && <span className="error">Name is required</span>}
          </div>
          <div className="form-group">
            <textarea
              type="text"
              name="description"
              className="form-control"
              ref={register({ required: true })}
              placeholder="*Description"
            />
            {errors.description && (
              <span className="error">Description is required</span>
            )}
          </div>

          <div className="d-flex">
            <div className="form-group">
              <button
                className="btn btn-danger btn-block shadow gradient-bg-accent"
                type="submit"
                disabled={!isDirty && !isValid}
              >
                Submit
              </button>
            </div>
            <div className="form-group">
              <Link to={"/projects"}>
                <button
                  className="cancel-button"
                  type="submit"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </div>

          {apiError && <span className="error">{apiError}</span>}
        </form>
        <ToastContainer />
      </div>
    </section>
  );
};

export default ProjectForm;
