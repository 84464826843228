import { MetaTags } from "react-meta-tags";

const Metatags = (props) => {
  const metatags = props.metatags;

  return (
    <MetaTags>
      <title>{metatags.title}</title>

      <meta name="title" content={metatags.title} />
      <meta name="description" content={metatags.description} />
      <meta name="keywords" content="App Upgrade, App Upgrade Manager, App Version Manager, Playstore App Update, App Store Updates, App Updates, Version Updates, Upgrade-Wall, Update-Wall, Huawei, Huawei AppGallery, Amazon AppStore" />
      <meta property="og:image" content={metatags.image} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={metatags.url} />
      <meta property="og:title" content={metatags.title} />
      <meta property="og:description" content={metatags.description} />
      <meta property="og:image" content={metatags.image} />

      <meta property="twitter:card" content={metatags.image} />
      <meta property="twitter:site" content="@app_upgrade"></meta>
      <meta property="twitter:url" content={metatags.url} />
      <meta property="twitter:title" content={metatags.title} />
      <meta property="twitter:description" content={metatags.description} />
      <meta property="twitter:image" content={metatags.image} />
    </MetaTags>
  );
};

export default Metatags;
