import React from "react";
import SkeletonLoader from "../Skeleton/SkeletonLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const AccountDetails = (props) => {
  const user = props.user;

  return (
    <div
      className="d-flex justify-content-between intro-container"
      id="support"
    >
      <div className="version-heading">
        <div className="account-components account-details">
          <div>
            <h5>
              <FontAwesomeIcon className="fa-thin mr-2" icon={faUser} />
              Profile Details
            </h5>
            <span>Your personal information</span>
          </div>
          <div className="version-info-box">
            <p>
              {user.name ? (
                <>
                  <b>Name:</b> {user.name}
                </>
              ) : (
                <SkeletonLoader />
              )}
            </p>
            <p>
              {user.email ? (
                <>
                  <b>Email:</b> {user.email}
                </>
              ) : (
                <SkeletonLoader />
              )}
            </p>
            <p>
              {user.createdAt ? (
                <>
                  <b>Member Since:</b> {new Date(user.createdAt).toDateString()}
                </>
              ) : (
                <SkeletonLoader />
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
