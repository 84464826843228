import React, { useState, useEffect } from "react";
import "./ProjectForm.css";
import { Link, useParams } from "react-router-dom";
import Constants from "../../Constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Empty from "../Empty/Empty";
import Swal from "sweetalert2";
import SkeletonLoader from "../Skeleton/SkeletonLoader";

const ViewProjectForm = (props) => {
  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    getProject(setProject, setIsLoading);
  }, [params.id]);

  function getProject(setProject, setIsLoading) {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    fetch(
      `${Constants.URL.SERVER_API_URL}/api/v1/projects/${
        params.id
      }?teamId=${localStorage.getItem("current_team")}`,
      options
    )
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          return res.json().then((version) => {
            setProject(version);
          });
        } else if (res.status >= 400 && res.status < 500) {
          return res.json().then((json) => {
            toast.error(json.message);
            setApiError(json.message);
          });
        } else {
          return res.json().then((json) => {
            throw new Error(json.message);
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
        setApiError("Uh oh!! something is not right!");
      });
  }

  let component;

  const skeleton = (
    <div className="col-md-12">
      <div className="version-info-box">
        <div className="mx-auto max-w-xl space-y-6">
          <div>
            <h4 className="text-md font-medium text-palette-800">
              <SkeletonLoader />
            </h4>
            <div className="mt-1.5 text-sm text-palette-500">
              <span>
                <SkeletonLoader />
              </span>
            </div>
            <div className="mt-3">
              <div className="mt-5">
                <div
                  className="row d-flex justify-content-between api-box"
                  style={{
                    margin: "0",
                    padding: "1.25em 1.4em",
                    width: "100%",
                  }}
                >
                  <div
                    className="col-md-6 gradient-text-accent "
                    style={{
                      margin: "0",
                      padding: "5px",
                      overflowX: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <p>
                      <SkeletonLoader />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="mt-1.5">
              <p className="mt-1">
                <SkeletonLoader />
                <SkeletonLoader />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  if (isLoading) {
    component = skeleton;
  } else if (apiError) {
    component = <Empty text={apiError} />;
  } else if (!project) {
    component = <Empty text="Project not found." />;
  } else {
    component = (
      <div>
        <div className="col-md-12">
          <div className="version-info-box">
            <div className="mx-auto max-w-xl space-y-6">
              <div>
                <h4 className="text-md font-medium text-palette-800">
                  API Key for {project.name}
                </h4>
                <div className="mt-1.5 text-sm text-palette-500">
                  <span>
                    API key is required for integration with App Upgrade. Each
                    project has it's own API key.
                  </span>
                </div>
                <div className="mt-3">
                  <div className="mt-5">
                    <div
                      className="row d-flex justfiy-content-between align-items-center api-box"
                      style={{
                        margin: "0",
                        padding: "1.25em 1.4em",
                        justifyContent: "space-between",
                        borderRadius: "0.2rem"
                      }}
                    >
                      <div className="col-md-8 project-key-box">
                        <p>{project.xApiKey}</p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                      <div className="mr-2">
                          <button
                            className="edit-button"
                            onClick={() => handleCopy(project)}
                          >
                            Copy
                          </button>
                        </div>
                        <div>
                          <button
                            className="delete-button"
                            onClick={() => handleChangeApiKey(project)}
                          >
                            Change API Key
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div className="mt-1.5">
                  <p className="mt-1">
                    Not sure how to use API Key to integrate with App Upgrade?
                    You can learn about our API and how to integrate using SDK
                    from our{" "}
                    <span>
                      <a
                        target="_blank"
                        href="https://appupgrade.dev/docs/"
                        className="delete-button gradient-text-accent font-weight-bold"
                      >
                        {" "}
                        Documentation
                      </a>
                    </span>{" "}
                    page.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handleCopy = (project) => {
    navigator.clipboard.writeText(project.xApiKey);
    toast.success("Copied API Key.");
  };

  const handleChangeApiKey = (project) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "shadow",
        cancelButton: "shadow",
      },
      buttonsStyling: true,
    });


    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        footer: "<p>Understand the potential consequences of modifying the API key from <a href='http://localhost:4000/app-upgrade-faq.html#what-will-happen-to-the-existing-app-releases-if-the-api-key-is-changed-and-existing-apps-using-old-api-key-does-the-force-upgrade-for-those-app-versions-will-stop-working'>here.</a></p>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, change it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          // Delete call here.
          const options = {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify({
              xApiKey: project.xApiKey,
            }),
          };

          fetch(
            `${Constants.URL.SERVER_API_URL}/api/v1/projects/${
              project.id
            }/api-key?teamId=${localStorage.getItem("current_team")}`,
            options
          )
            .then((res) => {
              if (res.status === 200) {
                toast.success("API key changed successfully.");
                swalWithBootstrapButtons.fire(
                  "Changed!",
                  "API Key has been changed.",
                  "success"
                );
                getProject(setProject, setIsLoading);
              } else {
                return res.json().then((json) => {
                  toast.error(json.message);
                });
              }
            })
            .catch((err) => {
              toast.error(err.message);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Action is cancelled. :)",
            "error"
          );
        }
      });
  };

  return (
    <section className="project-area my-5">
      <div className="container">
        <div className="d-flex justify-content-between intro-container">
          <div className="version-heading">
            <div className="d-flex justify-content">
              <div>
                <p>
                  <Link className="gradient-text-accent" to={"/projects"}>
                    Projects
                  </Link>{" "}
                  <FontAwesomeIcon className="fa-solid" icon={faAngleRight} />{" "}
                  API Key
                </p>
              </div>
            </div>
          </div>
        </div>
        {component}
      </div>
      <ToastContainer />
    </section>
  );
};

export default ViewProjectForm;
