import React from "react";
import { useState, useEffect } from "react";
import Empty from "../Empty/Empty";
import Table from "../Table/Table";
import Button from "react-bootstrap/Button";
import { useMemo } from "react";
import Constants from "../../Constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Activity.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWaveSquare, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import SkeletonLoader from "../Skeleton/SkeletonLoader";
import Android from "../../Images/android-logo-sdk.png";
import iOS from "../../Images/Apple_logo_black.svg.png";
import AppTooltip from "../AppTooltip/AppTooltip";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const VersionInteractionLog = (props) => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [interactionLogs, setInteractionLogs] = useState([]);
  const [limit] = useState(5);
  const [hasNext, setHasNext] = useState(false);
  const [next, setNext] = useState(null);

  let component;

  const columns = useMemo(() => [
    {
      Header: "Project",
      accessor: "event.message.p",
      Cell: ({ row }) => {
        const data = {
          timestamp: row.original.timestamp,
          event: JSON.parse(row.original.event),
        };

        return data.event.message.p ? (
          <AppTooltip placement="bottom" overlay={data.event.message.p}>
            <span className="overflow">{data.event.message.p}</span>
          </AppTooltip>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "App Name",
      accessor: "event.message.n",
      Cell: ({ row }) => {
        const data = {
          timestamp: row.original.timestamp,
          event: JSON.parse(row.original.event),
        };
        return data.event.message.n ? data.event.message.n : "N/A";
      },
    },
    {
      Header: "App Version",
      accessor: "event.message.v",
      Cell: ({ row }) => {
        const data = {
          timestamp: row.original.timestamp,
          event: JSON.parse(row.original.event),
        };
        return (
          <div className="badge version">
            <p>{data.event.message.v ? data.event.message.v : "N/A"}</p>
          </div>
        );
      },
    },
    {
      Header: "Platform",
      accessor: "event.message.pl",
      Cell: ({ row }) => {
        const data = {
          timestamp: row.original.timestamp,
          event: JSON.parse(row.original.event),
        };
        return data.event.message.pl &&
          (data.event.message.pl === "android" ||
            data.event.message.pl === "Android") ? (
          <div
            className="d-flex justify-content: space-around"
            style={{ justifyContent: "center" }}
          >
            <img
              className="platform-logo-android"
              src={Android}
              alt="android"
            />{" "}
            <p style={{ paddingLeft: "8px" }}></p>
          </div>
        ) : data.event.message.pl &&
          (data.event.message.pl === "iOS" ||
            data.event.message.pl === "ios") ? (
          <div
            className="d-flex justify-content: space-around"
            style={{ justifyContent: "center" }}
          >
            <img className="platform-logo-ios" src={iOS} alt="iOS" />{" "}
            <p style={{ paddingLeft: "8px" }}></p>
          </div>
        ) : (
          <div
            className="d-flex justify-content: space-around"
            style={{ justifyContent: "center" }}
          >
            <p style={{ paddingLeft: "5px" }}>
              {data.event.message.pl ? data.event.message.pl : "N/A"}
            </p>
          </div>
        );
      },
    },
    {
      Header: "Environment",
      accessor: "event.message.e",
      Cell: ({ row }) => {
        const data = {
          timestamp: row.original.timestamp,
          event: JSON.parse(row.original.event),
        };
        return data.event.message.e ? data.event.message.e : "N/A";
      },
    },
    {
      Header: "Matched",
      accessor: "event.message.f",
      Cell: ({ row }) => {
        const data = {
          timestamp: row.original.timestamp,
          event: JSON.parse(row.original.event),
        };
        return (
          <div className="badge version">
            <p>{data.event.message.f ? "Yes" : "No"}</p>
          </div>
        );
      },
    },
    {
      Header: "Force Upgrade",
      accessor: "event.message.u",
      Cell: ({ row }) => {
        const data = {
          timestamp: row.original.timestamp,
          event: JSON.parse(row.original.event),
        };
        return data.event.message.u ? (
          <div className="badge upgrade-toggle">
            <p>Yes</p>
          </div>
        ) : (
          <div className="badge version">
            <p>No</p>
          </div>
        );
      },
    },
    {
      Header: "Timestamp",
      accessor: "timestamp",
      Cell: ({ row }) => {
        const data = {
          timestamp: row.original.timestamp,
          event: JSON.parse(row.original.event),
        };
        return new Date(data.timestamp / 10 ** 6).toLocaleString();
      },
    },
  ]);

  useEffect(() => {
    getInteractionLogs(
      limit,
      setNext,
      setHasNext,
      interactionLogs,
      setInteractionLogs,
      setIsLoading,
      setIsLoadMore,
      setApiError
    );
  }, []);

  const skeleton = (
    <div className="table-responsive">
      <table role="table" className="shadow table table-hover">
        <thead>
          <tr role="row">
            <th colSpan="1" role="columnheader">
              <SkeletonLoader />
            </th>
            <th colSpan="1" role="columnheader">
              <SkeletonLoader />
            </th>
            <th colSpan="1" role="columnheader">
              <SkeletonLoader />
            </th>
            <th colSpan="1" role="columnheader">
              <SkeletonLoader />
            </th>
            <th colSpan="1" role="columnheader">
              <SkeletonLoader />
            </th>
            <th colSpan="1" role="columnheader">
              <SkeletonLoader />
            </th>
            <th colSpan="1" role="columnheader">
              <SkeletonLoader />
            </th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <tr role="row">
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader width={50} />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader width={50} />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
          </tr>
          <tr role="row">
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader width={50} />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader width={50} />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
          </tr>
          <tr role="row">
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader width={50} />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
            <td role="cell">
              <SkeletonLoader width={50} />
            </td>
            <td role="cell">
              <SkeletonLoader />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  if (isLoading || isRefreshing) {
    component = skeleton;
  } else if (apiError) {
    component = <Empty text={apiError} />;
  } else if (interactionLogs.length === 0) {
    component = <Empty text="No events available at the moment." />;
  } else {
    component = (
      <div>
        <Table columns={columns} data={interactionLogs} />
        {hasNext ? (
          <div className="pagination-container" style={{ textAlign: "center" }}>
            <Button
              className="shadow purple-button"
              variant="primary"
              disabled={!hasNext}
              type="submit"
              onClick={() => handleNext()}
            >
              {isLoadMore ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Load more"
              )}
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }

  const handleNext = () => {
    getInteractionLogs(
      limit,
      setNext,
      setHasNext,
      interactionLogs,
      setInteractionLogs,
      setIsLoading,
      setIsLoadMore,
      setApiError
    );
  };

  const handleRefresh = () => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    let url = `${Constants.URL.SERVER_API_URL}/api/v1/versions/interaction-logs?vId=${params.versionId}`;

    setIsRefreshing(true);

    fetch(url, options)
      .then((res) => {
        setIsRefreshing(false);
        if (res.status === 200) {
          return res.json().then((interactionLogsResponse) => {
            if (interactionLogsResponse.next) {
              setNext(interactionLogsResponse.next);
              setHasNext(true);
            } else {
              setHasNext(false);
            }
            setInteractionLogs(interactionLogsResponse.events);
          });
        } else if (res.status >= 400 && res.status < 500) {
          return res.json().then((json) => {
            setApiError(json.message);
          });
        } else {
          return res.json().then((json) => {
            throw new Error(json.message);
          });
        }
      })
      .catch((err) => {
        setIsRefreshing(false);
        setApiError("Uh oh!! something is not right!");
      });
  };

  function getInteractionLogs(
    limit,
    setNext,
    setHasNext,
    interactionLogs,
    setInteractionLogs,
    setIsLoading,
    setIsLoadMore,
    setApiError
  ) {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    let url = `${Constants.URL.SERVER_API_URL}/api/v1/versions/interaction-logs?vId=${params.versionId}`;

    if (next) {
      url = url + `&next=${next}`;
    }
    setIsLoadMore(true);
    fetch(url, options)
      .then((res) => {
        setIsLoading(false);
        setIsLoadMore(false);
        if (res.status === 200) {
          return res.json().then((interactionLogsResponse) => {
            if (interactionLogsResponse.next) {
              setNext(interactionLogsResponse.next);
              setHasNext(true);
            } else {
              setHasNext(false);
            }
            setInteractionLogs(
              interactionLogs.concat(interactionLogsResponse.events)
            );
          });
        } else if (res.status >= 400 && res.status < 500) {
          return res.json().then((json) => {
            setApiError(json.message);
          });
        } else {
          return res.json().then((json) => {
            throw new Error(json.message);
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsLoadMore(false);
        setApiError("Uh oh!! something is not right!");
      });
  }

  return (
    <section className="project-area my-5">
      <div className="container">
        <div className="d-flex justify-content-between intro-container">
          <div className="version-heading">
            <div className="d-flex justify-content">
              <div>
                <p>
                  <Link className="gradient-text-accent" to={"/projects"}>
                    Projects
                  </Link>{" "}
                  <FontAwesomeIcon className="fa-solid" icon={faAngleRight} />{" "}
                  <Link
                    className="gradient-text-accent"
                    to={"/projects/" + params.id + "/versions"}
                  >
                    Versions{" "}
                  </Link>
                  <FontAwesomeIcon className="fa-solid" icon={faAngleRight} />{" "}
                  Events
                </p>
              </div>
            </div>
            <AppTooltip
              placement="bottom"
              overlay="Shows events for upto last 14 days and max 100 events. This feature is still in beta and may not be accurate."
            >
              <span className="vs-tooltip-content">
                Here you can see the events we are receiving from your app.
              </span>
            </AppTooltip>
          </div>
          <div>
            <button className="text-purple" onClick={() => handleRefresh()}>
              <FontAwesomeIcon className="fa-thin mr-2" icon={faSyncAlt} />
              Refresh
            </button>
          </div>
        </div>
        <div className="interaction-table">{component}</div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default VersionInteractionLog;
