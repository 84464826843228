import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import Constants from "../../Constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import SkeletonLoader from "../Skeleton/SkeletonLoader";

const Invitation = () => {
  const [searchParams] = useSearchParams();

  const [invitation, setInvitation] = useState([]);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    const options = {
      method: "GET",
    };

    fetch(
      `${Constants.URL.SERVER_API_URL}/api/v1/invitations/${searchParams.get(
        "token"
      )}`,
      options
    )
      .then((res) => {
        if (res.status === 200) {
          return res.json().then((invitation) => {
            setInvitation(invitation);
          });
        } else if (res.status >= 400 && res.status < 500) {
          return res.json().then((json) => {
            toast.error(json.message);
            setApiError(json.message);
          });
        } else {
          return res.json().then((json) => {
            throw new Error(json.message);
          });
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setApiError("Uh oh!! something is not right!");
      });
  }, []);

  const invitationCard = (
    <div className="sign-up">
      <div className="container">
        <div className="logo text-center py-4"></div>
        <form className="py-5 shadow">
          <div className="form-group">
            <div>
              {invitation.name ? (
                <h4 className="text-white">Hello👋, {invitation.name}</h4>
              ) : (
                <SkeletonLoader className="landing-loading-skeleton"/>
              )}
            </div>
            <div className="landing-muted-text mt-5">
              {invitation.teamId ? (
                <p>
                  <b>
                    <snap className="text-purple">
                      {invitation.teamId.name}
                    </snap>{" "}
                    has invited you to join their team.
                  </b>
                </p>
              ) : (
                <div className="mt-5">
                  <SkeletonLoader className="mt-5 landing-loading-skeleton" />
                  <SkeletonLoader className="landing-loading-skeleton" />
                </div>
              )}

              {invitation.teamId ? (
                <p className="mt-5">
                  To join the team click on signup below to create account and
                  join the team.
                </p>
              ) : (
                <div className="mt-5">
                  <SkeletonLoader className="mt-5 landing-loading-skeleton" />
                  <SkeletonLoader className="landing-loading-skeleton" />
                  <SkeletonLoader className="landing-loading-skeleton" />
                </div>
              )}

              {invitation.teamId ? (
                <p>If you already have an account Signin to join the team.</p>
              ) : (
                <div className="mt-5">
                  <SkeletonLoader className="landing-loading-skeleton" />
                  <SkeletonLoader className="landing-loading-skeleton" />
                </div>
              )}
            </div>
            <br></br>
            {invitation.teamId ? (
              <Link
                to={`/signup?token=${searchParams.get("token")}`}
                className="nav-link"
                data-toggle="collapse"
              >
                <button
                  className="btn btn-danger shadow gradient-bg-accent"
                  type="submit"
                >
                  {" "}
                  <FontAwesomeIcon
                    className="fa-thin mr-2"
                    icon={faSignInAlt}
                  />
                  Sign up
                </button>
              </Link>
            ) : (
              <div className="alternate-login">
                <SkeletonLoader className="landing-loading-skeleton" />
              </div>
            )}
          </div>
          {invitation.teamId ? (
            <div className="alternate-login">
              <span className="alternate-login-span">OR</span>
            </div>
          ) : (
            <div className="alternate-login">
              <SkeletonLoader className="landing-loading-skeleton" />
            </div>
          )}
          {invitation.teamId ? (
            <div className="option text-center">
              <Link
                to={`/signin?token=${searchParams.get("token")}`}
                className="nav-link"
                data-toggle="collapse"
              >
                <label>Already Have an Account? Sign in</label>
              </Link>
            </div>
          ) : (
            <div className="option text-center">
              <SkeletonLoader className="landing-loading-skeleton" />
              <SkeletonLoader className="landing-loading-skeleton" />
            </div>
          )}
        </form>
      </div>
      <ToastContainer />
    </div>
  );

  const errorCard = (
    <div className="sign-up">
      <div className="container">
        <div className="logo text-center py-4"></div>
        <form className="py-5 shadow">
          <div className="form-group">
            <div>
              <h4 className="text-white">Hello👋 There,</h4>
            </div>
            <div>
              <p className="text-purple mt-5">
                <b>This invitation is not active or is expired.</b>
              </p>

              <p className="mt-5 landing-muted-text">
                Please try again or contact your team's admin.
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  return <>{apiError ? errorCard : invitationCard}</>;
};

export default Invitation;
