import React from "react";

const Status = () => {
  return (
    <section className="project-area">
      <iframe
        src="https://status.appupgrade.dev"
        title="App Upgrade: Status Page"
        frameborder="0"
        style={{ overflow: "hidden", height: "100vh", width: "100%" }}
        height="100vh"
        width="100%"
      ></iframe>
    </section>
  );
};

export default Status;
