import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

const Apps = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [apps, setApps] = useState([]);

  useEffect(() => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(`https://app-upgrade-assets.s3.amazonaws.com/apps.json`, options)
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          return res.json().then((data) => {
            setApps(data);
          });
        } else if (res.status >= 400 && res.status < 500) {
          return res.json().then((json) => {});
        } else {
          return res.json().then((json) => {
            throw new Error(json.message);
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  return (
    <section className="landing-page-section bg-landing-page" id="marketplace">
      <Container>
        <Col className="text-center">
          <Col lg={12} md={12}>
            <div className="title">
              <h1 className="text-white text-landing-h1">
                Apps Using App Upgrade{" "}
              </h1>
              <p
                className="landing-muted-text"
                style={{ fontSize: "1.25rem" }}
              ></p>
            </div>
          </Col>
          <Col lg={12} md={12}>
            <Row className="flex justify-content-center mt-10">
              {apps.map((app) => (
                <Col md={4} xs={6} lg={2}>
                  <a target="_blank" href={app.playstore} rel="noreferrer">
                    <div
                      className="customer-card text-center"
                      style={{ borderRadius: "0.5rem" }}
                    >
                      <div className="card-body">
                        <div className="d-flex flex-column align-items-center">
                          <img
                            width="64px"
                            height="auto"
                            alt="logo"
                            src={app.image}
                            style={{ borderRadius: "1rem" }}
                          />
                          <span
                            className="landing-muted-text text-sm mt-1"
                            style={{ fontSize: "0.8rem" }}
                          >
                            {app.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </a>
                </Col>
              ))}
            </Row>
          </Col>
        </Col>
      </Container>
    </section>
  );
};

export default Apps;
