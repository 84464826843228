import React, { useState } from "react";
import "./SignIn.css";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "./UseAuth";
import { GoogleLogin } from "react-google-login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";

const SignIn = (props) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [returningUser, setReturningUser] = useState(true);
  const [apiError, setApiError] = useState(null);
  const { register, handleSubmit, watch, errors } = useForm();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const auth = useAuth();

  const onSubmit = (data) => {
    if (forgotPassword) {
      setIsLoading(true);
      auth
        .forgotPassword(data.email)
        .then((data) => {
          setApiError(data.message);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setApiError(err.message);
        });
    } else if (returningUser) {
      if (data.email && data.password) {
        setIsLoading(true);
        auth
          .signIn(data.email, data.password, searchParams.get("token"))
          .then((data) => {
            auth.setUserData(data).then(() => {
              setIsLoading(false);
              navigate("/dashboard");
            });
          })
          .catch((err) => {
            setIsLoading(false);
            setApiError(err.message);
          });
      }
    } else {
      if (data.name && data.email && data.password && data.confirm_password) {
        setIsLoading(true);
        auth
          .signUp(
            data.name,
            data.email,
            data.confirm_password,
            searchParams.get("token")
          )
          .then((data) => {
            auth.setUserData(data).then(() => {
              setIsLoading(false);
              navigate("/dashboard");
            });
          })
          .catch((err) => {
            setIsLoading(false);
            setApiError(err.message);
          });
      }
    }
  };

  const onGoogleSuccess = (response) => {
    const accessToken = response.accessToken;
    auth
      .googleSignin(accessToken, searchParams.get("token"))
      .then((data) => {
        auth.setUserData(data).then(() => {
          setIsLoading(false);
          navigate("/dashboard");
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setApiError(err.message);
      });
  };

  const onGoogleFailure = () => {
    setIsLoading(false);
    setApiError("Something went wrong!");
  };

  const googleLogin = (
    <div
      style={{
        height: "7vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        color: "white",
      }}
    >
      <GoogleLogin
        clientId="809913367580-9bufrf873e4jjbd7l11rco9o1h17q6ik.apps.googleusercontent.com"
        buttonText="Continue with Google"
        onSuccess={onGoogleSuccess}
        onFailure={onGoogleFailure}
        className="google-login-button"
      />
    </div>
  );

  const forgotPasswordForm = (
    <form onSubmit={handleSubmit(onSubmit)} className="py-5 shadow">
      <div className="form-group">
        <input
          name="email"
          className="form-control"
          ref={register({ required: true })}
          placeholder="*Email"
        />
        {errors.email && <span className="error">Email is required</span>}
      </div>

      <div className="form-group">
        <button
          className="btn btn-danger btn-block shadow gradient-bg-accent"
          type="submit"
        >
          {isLoading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            "Submit"
          )}
        </button>
      </div>
      {apiError && <span className="error">{apiError}</span>}
      <div className="option text-center">
        <label onClick={() => setForgotPassword(false)}>Back to Sign in</label>
      </div>
    </form>
  );

  const signinForm = (
    <form onSubmit={handleSubmit(onSubmit)} className="py-5 shadow" id="signin">
      {googleLogin}
      <div className="alternate-login">
        <span className="alternate-login-span">OR</span>
      </div>
      <div className="form-group">
        <input
          name="email"
          className="form-control"
          ref={register({ required: true })}
          placeholder="*Email"
        />
        {errors.email && <span className="error">Email is required</span>}
      </div>
      <div className="form-group">
        <input
          type="password"
          name="password"
          className="form-control"
          ref={register({ required: true })}
          placeholder="*Password"
        />
        {errors.password && <span className="error">Password is required</span>}
      </div>

      <div className="form-group">
        <button
          className="btn btn-danger btn-block shadow gradient-bg-accent"
          type="submit"
        >
          {isLoading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <div>
              <FontAwesomeIcon className="fa-thin mr-2" icon={faSignInAlt} />
              Sign in
            </div>
          )}
        </button>
      </div>
      {apiError && <span className="error">{apiError}</span>}
      <div className="d-flex justify-content-between">
        <div className="option text-center">
          <label onClick={() => setForgotPassword(true)}>
            Forgot Password?
          </label>
        </div>
        <div className="option text-center">
          <label onClick={() => setReturningUser(false)}>
            Create a new Account
          </label>
        </div>
      </div>
      <hr></hr>
      <span className="landing-muted-text">
        By signing in you agree to the{" "}
        <a target="_" href="https://appupgrade.dev/terms-of-service">
          Terms of Service
        </a>{" "}
        and{" "}
        <a target="_" href="https://appupgrade.dev/privacy-policy">
          Privacy Policy
        </a>
      </span>
    </form>
  );

  const signupForm = (
    <form onSubmit={handleSubmit(onSubmit)} className="py-5 shadow" id="signup">
      {googleLogin}
      <div className="alternate-login">
        <span className="alternate-login-span">OR</span>
      </div>
      <div className="form-group">
        <input
          name="name"
          className="form-control"
          ref={register({ required: true })}
          placeholder="*Name"
        />
        {errors.name && <span className="error">Name is required</span>}
      </div>
      <div className="form-group">
        <input
          name="email"
          className="form-control"
          ref={register({ required: true })}
          placeholder="*Email"
        />
        {errors.email && <span className="error">Email is required</span>}
      </div>
      <div className="form-group">
        <input
          type="password"
          name="password"
          className="form-control"
          ref={register({ required: true })}
          placeholder="*Password"
        />
        {errors.password && <span className="error">Password is required</span>}
      </div>
      <div className="form-group">
        <input
          type="password"
          name="confirm_password"
          className="form-control"
          ref={register({
            validate: (value) => value === watch("password"),
          })}
          placeholder="*Confirm Password"
        />
        {errors.confirm_password && (
          <span className="error">Passwords don't match.</span>
        )}
      </div>
      <div className="form-group">
        <button
          className="btn btn-danger btn-block shadow gradient-bg-accent"
          type="submit"
        >
          {isLoading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <div>
              <FontAwesomeIcon className="fa-thin mr-2" icon={faSignInAlt} />
              Sign up
            </div>
          )}
        </button>
      </div>

      {apiError && <span className="error">{apiError}</span>}

      <div className="option text-center">
        <label onClick={() => setReturningUser(true)}>
          Already Have an Account? Sign in
        </label>
      </div>
      <hr></hr>
      <span className="landing-muted-text">
        By signing up you agree to the{" "}
        <a target="_" href="https://appupgrade.dev/terms-of-service">
          Terms of Service
        </a>{" "}
        and{" "}
        <a target="_" href="https://appupgrade.dev/privacy-policy">
          Privacy Policy
        </a>
      </span>
    </form>
  );

  return (
    <div className="sign-up">
      <div className="container">
        <div className="logo text-center py-4"></div>
        {forgotPassword
          ? forgotPasswordForm
          : returningUser
          ? signinForm
          : signupForm}
      </div>
    </div>
  );
};

export default SignIn;
