import React from "react";
import "./Empty.css";
import EMPTY_SVG from "../../Images/empty.svg";

const Empty = (props) => {
  return (
    <section className="project-area my-5">
      <div className="container">
        <div className="empty-container">
          <h1 className="empty-text">{props.text}</h1>
          {props.image === "no" ? (
            <></>
          ) : (
            <img
              className="col-md-4 col-sm-4 col-xs-4"
              src={EMPTY_SVG}
              alt="Empty Screen"
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default Empty;
