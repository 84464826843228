import React, { lazy, Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import { ReactComponent as DarkLogo } from "../../Images/dark-wordmark.svg";
import { ReactComponent as LightLogo } from "../../Images/light-wordmark.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBars,
  faSignInAlt,
  faSignOutAlt,
  faCreditCard,
  faBook,
  faUser,
  faBlog,
  faColumns,
  faFolderOpen,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../SignIn/UseAuth";
import { InputGroup } from "reactstrap";
import { ThemeContext, themes } from "../../Theme/ThemeContext";
import Constants from "../../Constants";
import { Dropdown } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DarkModeToggle = lazy(() => import("react-dark-mode-toggle"));
const renderLoader = () => {
  return <></>;
};

const Header = (props) => {
  const showBanner = props.show.showBanner;
  const preferred_theme = localStorage.getItem("preferred_theme");
  const [darkMode, setDarkMode] = React.useState(
    preferred_theme === themes.dark ? true : false
  );
  const auth = useAuth();
  auth.user = auth.getUserData();
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedTeamSubscription, setSelectedTeamSubscription] = useState([]);
  const [selectedTeamFlag, setSelectedTeamFlag] = useState([]);
  const [landingPage, setLandingPage] = useState(true);

  useEffect(() => {
    if (auth.user && props.show.teamTab) {
      setLandingPage(false);
    }
    if (auth.user && props.show.teamTab) {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      fetch(`${Constants.URL.SERVER_API_URL}/api/v1/teams`, options)
        .then((res) => {
          if (res.status === 200) {
            return res.json().then((teams) => {
              setTeams(teams);

              if (!localStorage.getItem("current_team")) {
                localStorage.setItem("current_team", teams[0].id);
                localStorage.setItem("current_team_name", teams[0].name);
                localStorage.setItem(
                  "current_team_subscription",
                  teams[0].hasSubscription
                );
                window.dispatchEvent(new Event("storage"));
                setSelectedTeam(teams[0].name);
                setSelectedTeamSubscription(teams[0].hasSubscription);
                setSelectedTeamFlag(teams[0].flag);
              } else {
                if (
                  !teams.find(
                    (team) =>
                      team.id.toString() ===
                      localStorage.getItem("current_team")
                  )
                ) {
                  localStorage.setItem("current_team", teams[0].id);
                  localStorage.setItem("current_team_name", teams[0].name);
                  localStorage.setItem(
                    "current_team_subscription",
                    teams[0].hasSubscription
                  );

                  window.dispatchEvent(new Event("storage"));
                  setSelectedTeam(teams[0].name);
                  setSelectedTeamSubscription(teams[0].hasSubscription);
                  setSelectedTeamFlag(teams[0].flag);
                } else {
                  window.dispatchEvent(new Event("storage"));
                  setSelectedTeam(
                    teams.find(
                      (team) =>
                        team.id.toString() ===
                        localStorage.getItem("current_team")
                    ).name
                  );
                  setSelectedTeamSubscription(
                    teams.find(
                      (team) =>
                        team.id.toString() ===
                        localStorage.getItem("current_team")
                    ).hasSubscription
                  );
                  setSelectedTeamFlag(
                    teams.find(
                      (team) =>
                        team.id.toString() ===
                        localStorage.getItem("current_team")
                    ).flag
                  );
                }
              }
            });
          } else if (res.status >= 400 && res.status < 500) {
            return res.json().then((json) => {
              toast.error(
                "Uh oh!! something is not right! Please try again later."
              );
            });
          } else {
            return res.json().then((json) => {
              toast.error(
                "Uh oh!! something is not right! Please try again later."
              );
            });
          }
        })
        .catch((err) => {
          toast.error(
            "Uh oh!! something is not right! Please try again later."
          );
        });
    }
  }, []);

  const handleTeamSelect = (evtKey, evt) => {
    setSelectedTeam(teams[evtKey].name);
    setSelectedTeamSubscription(teams[evtKey].hasSubscription);
    setSelectedTeamFlag(teams[evtKey].flag);

    localStorage.setItem("current_team", teams[evtKey].id);
    localStorage.setItem("current_team_name", teams[evtKey].name);
    localStorage.setItem(
      "current_team_subscription",
      teams[evtKey].hasSubscription
    );
    window.dispatchEvent(new Event("storage"));
    window.location.href = "/dashboard";
  };

  let bannerComponent;

  if (selectedTeamSubscription && selectedTeamSubscription === true) {
    localStorage.setItem("force-payment", false);
  }


  if (selectedTeamFlag === "PayProGlobal") {
    bannerComponent = (
      <div className="banner font-weight-bold">
        <p style={{ margin: "0px" }}>
        ⚠️ Renew your subscription to ensure uninterrupted access. {" "}
          <a
            className="gradient-text-accent font-weight-bold"
            href="/checkout"
          >
            Renew Subscription
          </a>
        </p>
      </div>
    );
  } else if (auth.user && auth.user.createdAt && selectedTeamSubscription === false) {
    const userTimestamp =
      new Date(auth.user.createdAt).getTime() + 15 * 24 * 60 * 60 * 1000;
    const todaysTimestamp = new Date().getTime();

    if (userTimestamp > todaysTimestamp) {
      const difference_in_time = userTimestamp - todaysTimestamp;
      const difference_in_days = difference_in_time / (1000 * 3600 * 24);
      bannerComponent = (
        <div className="banner font-weight-bold">
          <p style={{ margin: "0px" }}>
            Your App Upgrade trial ends in {difference_in_days.toFixed()} days.
            Buy{" "}
            <a
              className="gradient-text-accent font-weight-bold"
              href="/checkout"
            >
              Subscription
            </a>
          </p>
        </div>
      );
    } else if (
      userTimestamp < todaysTimestamp &&
      selectedTeamSubscription === false
    ) {
      bannerComponent = (
        <div className="banner font-weight-bold">
          <p style={{ margin: "0px" }}>
            Your App Upgrade trial is expired. Buy{"  "}
            <a
              className="gradient-text-accent font-weight-bold"
              href="/checkout"
            >
              Subscription
            </a>
          </p>
        </div>
      );
      localStorage.setItem("force-payment", true);
    }
  } else {
    if (auth.user && selectedTeamSubscription === false) {
      bannerComponent = (
        <div className="banner font-weight-bold">
          <p style={{ margin: "0px" }}>
            Loved App Upgrade?. Buy{"  "}
            <a
              className="gradient-text-accent font-weight-bold"
              href="/checkout"
            >
              Subscription
            </a>
          </p>
        </div>
      );
    }
  }

  return (
    <div>
      <ToastContainer />
      <nav
        className={`navbar navbar-expand-xl ${landingPage ? "bg-landing-page" : "bg-white"
          }`}
        id="navbar"
      >
        <div className="container">
          {!darkMode && props.show.teamTab ? (
            <Link to="/" className="navbar-brand">
              <LightLogo className="logo" />
            </Link>
          ) : (
            <Link to="/" className="navbar-brand">
              <DarkLogo className="logo" />
            </Link>
          )}

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#colNav"
            aria-label="Navbar Toggle"
          >
            <span style={{ color: `${landingPage ? "#eee" : ""}` }}>
              <FontAwesomeIcon className="fa-duotone fa-bars" icon={faBars} />
            </span>
          </button>

          {auth.user && props.show.teamTab && teams.length ? (
            <div className="d-none d-lg-block" style={{ marginTop: "0.3rem" }}>
              <Dropdown onSelect={handleTeamSelect}>
                <Dropdown.Toggle
                  id="dropdown-team-switch"
                  className="dropdown-team-toggle text-purple"
                >
                  <span className="text-purple team-name-ellipsis">
                    <FontAwesomeIcon
                      className="fa-solid mr-2"
                      icon={faUserFriends}
                    />
                    <b>{selectedTeam}</b>
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="super-colors">
                  <Dropdown.Header>Select Team</Dropdown.Header>
                  {teams.map((team, index) => {
                    return (
                      <div key={index}>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          key={index}
                          eventKey={index}
                          value={team.id}
                          className="nav-link"
                        >
                          <span className="text-nav nav-link">{team.name}</span>
                        </Dropdown.Item>
                      </div>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <></>
          )}

          <div
            className="collapse navbar-collapse justify-content-end"
            id="colNav"
          >
            <ul className="navbar-nav align-items-center" id="#colNav">
              {auth.user && props.show.teamTab && teams.length ? (
                <div className="d-lg-none">
                  <Dropdown onSelect={handleTeamSelect}>
                    <Dropdown.Toggle
                      id="dropdown-team-switch"
                      className="dropdown-team-toggle text-purple"
                    >
                      <span className="text-purple team-name-ellipsis">
                        <FontAwesomeIcon
                          className="fa-solid mr-2"
                          icon={faUserFriends}
                        />
                        <b>{selectedTeam}</b>
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="super-colors">
                      <Dropdown.Header>Select Team</Dropdown.Header>
                      {teams.map((team, index) => {
                        return (
                          <div key={index}>
                            <Dropdown.Divider />
                            <Dropdown.Item
                              key={index}
                              eventKey={index}
                              value={team.id}
                              className="nav-link"
                            >
                              <span className="text-nav nav-link">
                                {team.name}
                              </span>
                            </Dropdown.Item>
                          </div>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : (
                <></>
              )}

              {auth.user && props.show.dashboardTab ? (
                <li className="nav-item" key="dashboard">
                  <Link
                    to="/dashboard"
                    className={landingPage ? "text-white nav-link" : `nav-link`}
                    data-toggle="collapse"
                  >
                    <FontAwesomeIcon
                      className="fa-solid mr-2"
                      icon={faColumns}
                    />
                    Dashboard
                  </Link>
                </li>
              ) : (
                <></>
              )}

              {auth.user && props.show.projectTab ? (
                <li className="nav-item" key="projects">
                  <Link
                    to="/projects"
                    className="nav-link"
                    data-toggle="collapse"
                  >
                    <FontAwesomeIcon
                      className="fa-solid mr-2"
                      icon={faFolderOpen}
                    />
                    Projects
                  </Link>
                </li>
              ) : (
                <></>
              )}

              {props.show.pricingTab ? (
                <li className="nav-item" key="pricing">
                  <Link
                    to="/pricing"
                    className={landingPage ? "text-white nav-link" : `nav-link`}
                    data-toggle="collapse"
                  >
                    <FontAwesomeIcon
                      className="fa-solid mr-2"
                      icon={faCreditCard}
                    />
                    Pricing
                  </Link>
                </li>
              ) : (
                <></>
              )}

              {props.show.docsTab ? (
                <li className="nav-item" key="docs">
                  <a
                    target="_"
                    href="https://appupgrade.dev/docs/"
                    className={landingPage ? "text-white nav-link" : `nav-link`}
                  >
                    <FontAwesomeIcon className="fa-solid mr-2" icon={faBook} />
                    Docs
                  </a>
                </li>
              ) : (
                <></>
              )}

              {props.show.blogTab ? (
                <li className="nav-item" key="blog">
                  <a
                    target="_"
                    href="https://appupgrade.dev/blog/"
                    className={landingPage ? "text-white nav-link" : `nav-link`}
                  >
                    <FontAwesomeIcon className="fa-solid mr-2" icon={faBlog} />
                    Blog
                  </a>
                </li>
              ) : (
                <></>
              )}

              {auth.user && props.show.accountTab ? (
                <li className="nav-item" key="account">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-team-switch">
                      <span className="text-nav">
                        {" "}
                        <FontAwesomeIcon
                          className="fa-solid mr-2"
                          icon={faUser}
                        />
                        Account
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      <Dropdown.Header className="text-cool-grey-600">
                        Signed in as
                      </Dropdown.Header>
                      <Dropdown.Item className="text-purple">
                        {localStorage.getItem("email")}
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item>
                        <Link to="/account" className="text-nav nav-link">
                          <FontAwesomeIcon
                            className="fa-solid mr-2"
                            icon={faCog}
                          />
                          Account & Subscription Settings
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Divider className="dropdown-divider-custom" />

                      <Dropdown.Item className="danger-hover hover-danger-text">
                        <Link
                          to="/signin"
                          className="nav-link"
                          onClick={() => {
                            auth.signOut();
                          }}
                        >
                          <FontAwesomeIcon
                            className="fa-solid mr-2"
                            icon={faSignOutAlt}
                          />
                          Sign Out
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              ) : (
                <></>
              )}

              <li className="nav-item" key="gotodashboard">
                {auth.user && props.show.goToDashboard ? (
                  <Link to="/dashboard" className="nav-link">
                    <button className="btn btn-rounded gradient-bg-accent">
                      <b>Go to Dashboard →</b>
                    </button>
                  </Link>
                ) : !auth.user ? (
                  <Link
                    to="/signin"
                    className="nav-link"
                    data-toggle="collapse"
                  >
                    <button className="btn btn-rounded gradient-bg-accent">
                      <FontAwesomeIcon
                        className="fa-thin mr-2"
                        icon={faSignInAlt}
                      />
                      Sign in
                    </button>
                  </Link>
                ) : (
                  <></>
                )}
              </li>
              {!landingPage ? (
                <li className="nav-item" data-toggle="collapse" key="theme">
                  <InputGroup>
                    <ThemeContext.Consumer>
                      {({ changeTheme }) => (
                        <Suspense fallback={renderLoader()}>
                          <DarkModeToggle
                            onChange={() => {
                              setDarkMode(!darkMode);
                              localStorage.setItem(
                                "preferred_theme",
                                darkMode ? themes.light : themes.dark
                              );
                              changeTheme(
                                darkMode ? themes.light : themes.dark
                              );
                            }}
                            checked={darkMode}
                            size={40}
                          />
                        </Suspense>
                      )}
                    </ThemeContext.Consumer>
                  </InputGroup>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>
      </nav>
      {showBanner ? bannerComponent : <div></div>}
    </div>
  );
};

export default Header;
