import "./App.css";
import React, { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import Metatags from "./Components/Metatags";
import Hero from "./Components/Landing/Hero";
import Header from "./Components/Header/Header";
/** Projects */
import Projects from "./Components/Projects/Projects";
import CreateProject from "./Components/Projects/CreateProject";
import EditProject from "./Components/Projects/EditProject";
import ViewProject from "./Components/Projects/ViewProject";
/** Versions */
import Version from "./Components/Versions/Version";
import EditVersion from "./Components/EditVersion/EditVersion";
import DuplicateVersion from "./Components/DuplicateVersion/DuplicateVersion";
import CreateVersion from "./Components/CreateVersion/CreateVersion";
import ViewVersion from "./Components/ViewVersion/ViewVersion";
/** Auth */
import SignIn from "./Components/SignIn/SignIn";
import Signup from "./Components/SignIn/Signup";
import ResetPassword from "./Components/SignIn/ResetPassword";
import Invitation from "./Components/Invitation/Invitation";
import Account from "./Components/Account/Account";
import Dashboard from "./Components/Dashboard/Dashboard";
import Status from "./Components/Status/Status";
/** Others */
import NotFound from "./Components/NotFound/NotFound";
import Checkout from "./Components/Checkout/Checkout";

import {
  AuthProvider,
  PrivateRoute,
  PublicRoute,
} from "./Components/SignIn/UseAuth";
import "bootstrap/dist/js/bootstrap.min.js";
import VersionInteractionLog from "./Components/Activity/VersionInteractionLog";
import Apps from "./Components/Landing/Apps";
import AppIDFinder from "./Components/Utilities/AppIDFinder";

/** Lazy Components */
const Footer = lazy(() => import("./Components/Landing/Footer"));
/** Landing pages */
const Flow = lazy(() => import("./Components/Landing/Flow"));
const Benefits = lazy(() => import("./Components/Landing/Benefits"));
const UseCase = lazy(() => import("./Components/Landing/UseCase"));
const Contact = lazy(() => import("./Components/Landing/Contact"));
const CTA = lazy(() => import("./Components/Landing/CTA"));
const Testimonial = lazy(() => import("./Components/Landing/Testimonial"));
const CheckoutFAQ = lazy(() => import("./Components/Checkout/CheckoutFAQ"));
const TermsOfService = lazy(() =>
  import("./Components/Landing/TermsOfService")
);
const PrivacyPolicy = lazy(() => import("./Components/Landing/PrivacyPolicy"));
const Changelog = lazy(() => import("./Components/Landing/Changelog"));
const Brand = lazy(() => import("./Components/Landing/Brand"));
const SampleUpdateMessage = lazy(() =>
  import("./Components/SampleUpdateMessage/SampleUpdateMessage")
);
const Utilities = lazy(() => import("./Components/Utilities/Utilties"));
const Base64Encoder = lazy(() =>
  import("./Components/Utilities/Base64Encoder")
);
const Base64Decoder = lazy(() =>
  import("./Components/Utilities/Base64Decoder")
);
const HexToRGB = lazy(() => import("./Components/Utilities/HexToRGB"));
const RGBToHSL = lazy(() => import("./Components/Utilities/RGBToHSL"));
const UUIDGenerator = lazy(() =>
  import("./Components/Utilities/UUIDGenerator")
);
const RandomNumberGenerator = lazy(() =>
  import("./Components/Utilities/RandomNumberGenerator")
);
const CORSChecker = lazy(() => import("./Components/Utilities/CORSChecker"));

const SDK = lazy(() => import("./Components/Landing/SDK"));
const Stats = lazy(() => import("./Components/Landing/Stats"));
const Customers = lazy(() => import("./Components/Landing/Customers"));
const AppReview = lazy(() => import("./Components/AppReview/AppReview"));
const AppReviews = lazy(() => import("./Components/Landing/AppReviews"));
const CreateAppReview = lazy(() =>
  import("./Components/AppReview/CreateAppReview")
);
const EditAppReview = lazy(() =>
  import("./Components/AppReview/EditAppReview")
);
const ViewAppReview = lazy(() =>
  import("./Components/AppReview/ViewAppReview")
);

const renderLoader = () => {
  return <div className="loading-bg"></div>;
};

function App() {
  function ScrollToTop({ children }) {
    let location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return children;
  }

  return (
    <AuthProvider>
      <Router>
        <ScrollToTop>
          <div className="App">
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Update your mobile apps effortlessly with App Upgrade. Keep users informed with the latest version upgrades and improve their experience. Try it now.",
                        url: "https://appupgrade.dev",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                      }}
                    />
                    <Hero />
                    <Suspense fallback={renderLoader()}>
                      <Flow />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <SDK />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Benefits />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Testimonial />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <UseCase />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Checkout skip={false} />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <CTA />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/invitation"
                element={
                  <PublicRoute>
                    <Metatags
                      metatags={{
                        title:
                          "Team Invite · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Join the Team Invite for App Upgrade and Effortlessly Update Your Apps with Ease. Stay in the loop with the latest upgrades and enhancements for a seamless app experience.",
                        url: "https://appupgrade.dev/invitation",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                      }}
                    />
                    <Invitation />
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </PublicRoute>
                }
              ></Route>
              <Route
                path="/signin"
                element={
                  <PublicRoute>
                    <Metatags
                      metatags={{
                        title:
                          "Signin · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Sign in to App Upgrade and Simplify Your App Updates with Ease. Experience a smoother, more efficient way to keep your apps up to date.",
                        url: "https://appupgrade.dev/signin",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                      }}
                    />
                    <SignIn />
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </PublicRoute>
                }
              ></Route>
              <Route
                path="/signup"
                element={
                  <PublicRoute>
                    <Metatags
                      metatags={{
                        title:
                          "Signup · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Sign up for App Upgrade and Revolutionize Your App Updates Effortlessly. Upgrade your app experience with a seamless and efficient way to keep your apps up to date.",
                        url: "https://appupgrade.dev/signup",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                      }}
                    />
                    <Signup />
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </PublicRoute>
                }
              ></Route>
              <Route
                path="/reset-password"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "Reset Password · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Reset Your Password for App Upgrade and Regain Access to Effortlessly Update Your Apps. Regain control of your app upgrades with ease and convenience.",
                        url: "https://appupgrade.dev/reset-password",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                      }}
                    />
                    <ResetPassword />
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "Dashboard · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Welcome to Your Dashboard for App Upgrade - Your Hub for Effortlessly Updating Your Apps. Take control of your app upgrades with ease and convenience.",
                        url: "https://appupgrade.dev/dashboard",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: true,
                        dashboardTab: true,
                        projectTab: true,
                        pricingTab: false,
                        docsTab: true,
                        blogTab: false,
                        accountTab: true,
                        showBanner: true,
                      }}
                    />
                    <Dashboard />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/projects"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "Projects · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Explore Projects on App Upgrade - Simplify the Process of Effortlessly Updating Your Apps. Manage and streamline your app updates with ease and convenience.",
                        url: "https://appupgrade.dev/projects",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: true,
                        dashboardTab: true,
                        projectTab: true,
                        pricingTab: false,
                        docsTab: true,
                        blogTab: false,
                        accountTab: true,
                        showBanner: true,
                      }}
                    />
                    <Projects />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/projects/create"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "Create Project · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Create a Project on App Upgrade and Streamline Your App Updates with Ease. Effortlessly update your apps and enhance your app management process.",
                        url: "https://appupgrade.dev/projects/create",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: true,
                        dashboardTab: true,
                        projectTab: true,
                        pricingTab: false,
                        docsTab: true,
                        blogTab: false,
                        accountTab: true,
                        showBanner: true,
                      }}
                    />
                    <CreateProject />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/projects/:id/versions"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "Versions · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Your Versions on App Upgrade - Seamlessly Update Your Apps with Ease. Stay in control and enhance your app experience effortlessly.",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: true,
                        dashboardTab: true,
                        projectTab: true,
                        pricingTab: false,
                        docsTab: true,
                        blogTab: false,
                        accountTab: true,
                        showBanner: true,
                      }}
                    />
                    <Version />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/projects/:id/edit"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "Edit Project · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Edit Your Project on App Upgrade - Refine and Effortlessly Update Your Apps with Ease. Fine-tune your app management for a seamless experience.",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: true,
                        dashboardTab: true,
                        projectTab: true,
                        pricingTab: false,
                        docsTab: true,
                        blogTab: false,
                        accountTab: true,
                        showBanner: true,
                      }}
                    />
                    <EditProject />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/projects/:id/view"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "Project API Key · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Access Your Project API Key on App Upgrade - Streamlining App Updates with Ease. Secure your app management process effortlessly for a seamless experience.",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: true,
                        dashboardTab: true,
                        projectTab: true,
                        pricingTab: false,
                        docsTab: true,
                        blogTab: false,
                        accountTab: true,
                        showBanner: true,
                      }}
                    />
                    <ViewProject />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/projects/:id/versions/:versionId"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "View Version · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "View Version Details on App Upgrade - Effortlessly Stay Updated with Your Apps. Explore the latest app updates with ease and convenience.",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: true,
                        dashboardTab: true,
                        projectTab: true,
                        pricingTab: false,
                        docsTab: true,
                        blogTab: false,
                        accountTab: true,
                        showBanner: true,
                      }}
                    />
                    <ViewVersion />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/projects/:id/versions/:versionId/edit"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "Edit Version · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Edit Version on App Upgrade - Enhance Your App Updates with Effortless Ease. Refine and optimize your app experience effortlessly.",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: true,
                        dashboardTab: true,
                        projectTab: true,
                        pricingTab: false,
                        docsTab: true,
                        blogTab: false,
                        accountTab: true,
                        showBanner: true,
                      }}
                    />
                    <EditVersion />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/projects/:id/versions/:versionId/duplicate"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "Duplicate Version · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Duplicate Version on App Upgrade - Easily Replicate App Updates with Effortless Precision. Streamline your app management process for a seamless experience.",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: true,
                        dashboardTab: true,
                        projectTab: true,
                        pricingTab: false,
                        docsTab: true,
                        blogTab: false,
                        accountTab: true,
                        showBanner: true,
                      }}
                    />
                    <DuplicateVersion />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/projects/:id/versions/:versionId/events"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "Events · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Explore Events on App Upgrade - Stay Informed for Effortless App Updates. Stay up-to-date with the latest happenings and enhancements, ensuring a seamless app experience.",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: true,
                        dashboardTab: true,
                        projectTab: true,
                        pricingTab: false,
                        docsTab: true,
                        blogTab: false,
                        accountTab: true,
                        showBanner: true,
                      }}
                    />
                    <VersionInteractionLog />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/projects/:id/versions/create"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "Create Version · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Create a New Version on App Upgrade - Streamline Your App Updates with Ease. Effortlessly enhance your app management process for a seamless experience.",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: true,
                        dashboardTab: true,
                        projectTab: true,
                        pricingTab: false,
                        docsTab: true,
                        blogTab: false,
                        accountTab: true,
                        showBanner: true,
                      }}
                    />
                    <CreateVersion />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/account"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "Account · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Manage Your Account on App Upgrade - Seamlessly Update Your Apps with Ease. Take control of your app experience effortlessly for smooth updates.",
                        url: "https://appupgrade.dev/account",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: true,
                        dashboardTab: true,
                        projectTab: true,
                        pricingTab: false,
                        docsTab: true,
                        blogTab: false,
                        accountTab: true,
                        showBanner: true,
                      }}
                    />
                    <Account />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/checkout"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "Checkout · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Proceed to Checkout on App Upgrade - Streamline Your App Updates with Ease. Effortlessly enhance your app management process for a seamless experience.",
                        url: "https://appupgrade.dev/checkout",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: true,
                        dashboardTab: true,
                        projectTab: true,
                        pricingTab: false,
                        docsTab: true,
                        blogTab: false,
                        accountTab: true,
                        goToDashboard: false,
                        showBanner: false,
                      }}
                    />
                    <Checkout />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/privacy-policy"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "Privacy Policy · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Privacy Policy for App Upgrade - Safeguarding Your Data as You Effortlessly Update Your Apps. Your privacy matters as we strive to provide you with a seamless app updating experience.",
                        url: "https://appupgrade.dev/privacy-policy",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <PrivacyPolicy />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/terms-of-service"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "Terms and Conditions · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Terms and Conditions for App Upgrade - Guiding Your Journey to Effortlessly Update Your Apps. Know the rules as you enjoy a smooth app upgrading experience.",
                        url: "https://appupgrade.dev/terms-of-service",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <TermsOfService />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/changelog"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "Changelog · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Changelog for App Upgrade - Track Updates as You Effortlessly Upgrade Your Apps. Stay informed about the latest improvements for a seamless app experience.",
                        url: "https://appupgrade.dev/changelog",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <Changelog />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/brand"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "Brand Assets · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Access Brand Assets for App Upgrade - Enhancing Your Apps with Effortless Updates. Explore our branding resources to improve your app experience.",
                        url: "https://appupgrade.dev/brand",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <Brand />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/status"
                element={
                  <>
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Status />
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/stats"
                element={
                  <>
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <Stats />
                      <CTA />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/customers"
                element={
                  <>
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <Customers />
                      <Apps />
                      <CTA />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/pricing"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "Pricing · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Explore Pricing on App Upgrade - Streamline Your App Updates with Ease. Discover the cost-effective options for effortless app updating.",
                        url: "https://appupgrade.dev/pricing",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Checkout skip={false} />
                    <Suspense fallback={renderLoader()}>
                      <CheckoutFAQ />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Contact />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/sample-app-update-messages"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "Sample App Update Messages · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "To help you with right app update message we have compiled a list of messages based on use cases",
                        url: "https://appupgrade.dev/sample-app-update-messages",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <SampleUpdateMessage />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/utilities"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "Developer utilities · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Developer utilities for common task by App Upgrade.",
                        url: "https://appupgrade.dev/utilities",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <Utilities />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/utilities/app-id-finder"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "App ID Finder · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Get the App ID of your app from playstore or appstore url.",
                        url: "https://appupgrade.dev/utilities/app-id-finder",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <AppIDFinder />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/utilities/base64-encoder"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "Base64 Encoder · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Encode string to Base64 format using this free tool by App Upgrade.",
                        url: "https://appupgrade.dev/utilities/base64-encoder",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <Base64Encoder />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/utilities/base64-decoder"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "Base64 Decoder · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Decode from Base64 string format using this free tool by App Upgrade.",
                        url: "https://appupgrade.dev/utilities/base64-decoder",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <Base64Decoder />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/utilities/hex-to-rgb"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "Hex to RGB color converter · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Convert any hexidecimal color code (#RRGGBB) to RGB (Red, Green, Blue) color using this free tool by App Upgrade.",
                        url: "https://appupgrade.dev/utilities/hex-to-rgb",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <HexToRGB />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/utilities/rgb-to-hsl"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "RGB to HSL color converter · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Convert any RGB (Red, Green, Blue) color to HSL (Hue, Saturation, Lightness) using this free tool by App Upgrade.",
                        url: "https://appupgrade.dev/utilities/rgb-to-hsl",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <RGBToHSL />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/utilities/uuid-generator"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "UUID Generator · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Create a universally unique identifier (UUID) using this free tool by App Upgrade.",
                        url: "https://appupgrade.dev/utilities/uuid-generator",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <UUIDGenerator />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/utilities/random-number-generator"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "Random Number Generator · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Generate random number using this free tool by App Upgrade.",
                        url: "https://appupgrade.dev/utilities/random-number-generator",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <RandomNumberGenerator />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/utilities/cors-checker"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "CORS Checker · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        description:
                          "Check CORS headers using this free tool by App Upgrade.",
                        url: "https://appupgrade.dev/utilities/cors-checker",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                        showBanner: false,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <CORSChecker />
                    </Suspense>
                    <Suspense fallback={renderLoader()}>
                      <Footer />
                    </Suspense>
                  </>
                }
              ></Route>
              <Route
                path="/app-reviews"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "App Reviews · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        url: "https://appupgrade.dev/app-reviews",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: true,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <AppReviews />
                    </Suspense>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/projects/:id/app-reviews"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "App Reviews · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: true,
                        dashboardTab: true,
                        projectTab: true,
                        pricingTab: false,
                        docsTab: true,
                        blogTab: false,
                        accountTab: true,
                        showBanner: true,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <AppReview />
                    </Suspense>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/projects/:id/app-reviews/create"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "Create App Review · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: true,
                        dashboardTab: true,
                        projectTab: true,
                        pricingTab: false,
                        docsTab: true,
                        blogTab: false,
                        accountTab: true,
                        showBanner: true,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <CreateAppReview />
                    </Suspense>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/projects/:id/app-reviews/:appReviewId/edit"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "Edit App Review · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: true,
                        dashboardTab: true,
                        projectTab: true,
                        pricingTab: false,
                        docsTab: true,
                        blogTab: false,
                        accountTab: true,
                        showBanner: true,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <EditAppReview />
                    </Suspense>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/projects/:id/app-reviews/:appReviewId"
                element={
                  <PrivateRoute>
                    <Metatags
                      metatags={{
                        title:
                          "View App Review · App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: true,
                        dashboardTab: true,
                        projectTab: true,
                        pricingTab: false,
                        docsTab: true,
                        blogTab: false,
                        accountTab: true,
                        showBanner: true,
                      }}
                    />
                    <Suspense fallback={renderLoader()}>
                      <ViewAppReview />
                    </Suspense>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="*"
                element={
                  <>
                    <Metatags
                      metatags={{
                        title:
                          "App Upgrade | Effortlessly Update Your Apps with App Upgrade.",
                        image: "https://appupgrade.dev/ogimage.png",
                      }}
                    />
                    <Header
                      show={{
                        teamTab: false,
                        dashboardTab: false,
                        projectTab: false,
                        pricingTab: true,
                        docsTab: true,
                        blogTab: true,
                        accountTab: false,
                        goToDashboard: false,
                        showBanner: false,
                      }}
                    />
                    <NotFound />
                  </>
                }
              ></Route>
            </Routes>
          </div>
        </ScrollToTop>
      </Router>
    </AuthProvider>
  );
}

export default App;
