import React, { useState, useEffect } from "react";
import "./ProjectForm.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Constants from "../../Constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Loader/Loader";
import Empty from "../Empty/Empty";

const EditProjectForm = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [apiError, setApiError] = useState(null);
  const { register, handleSubmit, errors, formState: { isSubmitting, isDirty, isValid } } = useForm({ mode: "onChange" });

  useEffect(() => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    fetch(
      `${Constants.URL.SERVER_API_URL}/api/v1/projects/${
        params.id
      }?teamId=${localStorage.getItem("current_team")}`,
      options
    )
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          return res.json().then((version) => {
            setProject(version);
          });
        } else if (res.status >= 400 && res.status < 500) {
          return res.json().then((json) => {
            toast.error(json.message);
            setApiError(json.message);
          });
        } else {
          return res.json().then((json) => {
            throw new Error(json.message);
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
        setApiError("Uh oh!! something is not right!");
      });
  }, [params.id]);

  const onSubmit = (data) => {
    if (data.name && data.description) {
      const options = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          name: data.name,
          description: data.description,
        }),
      };
      fetch(
        `${Constants.URL.SERVER_API_URL}/api/v1/projects/${
          params.id
        }?teamId=${localStorage.getItem("current_team")}`,
        options
      )
        .then((res) => {
          if (res.status === 200) {
            toast.success("Project updated successfully.");
            navigate("/projects");
          } else if (res.status >= 400 && res.status < 500) {
            return res.json().then((json) => {
              toast.error(json.message);
            });
          } else {
            return res.json().then((json) => {
              throw new Error(json.message);
            });
          }
        })
        .catch((err) => {
          toast.error("Uh oh!! something is not right!");
        });
    }
  };

  let component;

  if (isLoading) {
    component = <Loader />;
  } else if (apiError) {
    component = <Empty text={apiError} />;
  } else if (!project) {
    component = <Empty text="Project not found." />;
  } else {
    component = (
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className="form-group">
          <input
            name="name"
            className="form-control"
            ref={register({ required: true })}
            placeholder="*Name"
            defaultValue={project.name}
          />
          {errors.name && <span className="error">Name is required</span>}
        </div>
        <div className="form-group">
          <textarea
            type="text"
            name="description"
            className="form-control"
            ref={register({ required: true })}
            placeholder="*Description"
            defaultValue={project.description}
          />
          {errors.description && (
            <span className="error">Description is required</span>
          )}
        </div>

        <div className="d-flex">
          <div className="form-group">
            <button
              className="btn btn-danger btn-block shadow gradient-bg-accent"
              type="submit"
              disabled={!isDirty && !isValid}
            >
              Submit
            </button>
          </div>
          <div className="form-group">
            <Link to={"/projects"}>
              <button
                className="cancel-button"
                type="submit"
              >
                Cancel
              </button>
            </Link>
          </div>
        </div>

        {apiError && <span className="error">{apiError}</span>}
      </form>
    );
  }

  return (
    <section className="project-area my-5">
      <div className="container">
        <div className="d-flex justify-content-between intro-container">
          <div className="version-heading">
            <div className="d-flex justify-content">
              <div>
                <p>
                  <Link className="gradient-text-accent" to={"/projects"}>
                    Projects
                  </Link>{" "}
                  <FontAwesomeIcon className="fa-solid" icon={faAngleRight} />{" "}
                  Edit
                </p>
              </div>
            </div>
            <span>Edit project details.</span>
          </div>
        </div>
        {component}
      </div>
      <ToastContainer />
    </section>
  );
};

export default EditProjectForm;
