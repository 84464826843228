import React from "react";
import "./CreateProject.css";
import EditProjectForm from "../ProjectForm/EditProjectForm";
import DocBlock from "../DocBlock/DocBlock";

const EditProject = (props) => {
  return (
    <section className="project-area my-5">
      <div className="container">
        <EditProjectForm></EditProjectForm>
        <DocBlock></DocBlock>
      </div>
    </section>
  );
};

export default EditProject;
