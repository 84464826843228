import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "./ProjectItem.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import Constants from "../../Constants";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProjectItem = (props) => {
  const { id, name, description } = props.project;

  const handleDelete = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "shadow",
        cancelButton: "shadow",
      },
      buttonsStyling: true,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "Deleting the Project will also delete all of Versions created inside project and API Key and you won't be able revert this.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const options = {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          };

          fetch(
            `${Constants.URL.SERVER_API_URL}/api/v1/projects/${id}?teamId=${localStorage.getItem("current_team")}`,
            options
          )
            .then((res) => {
              if (res.status === 204) {
                toast.info("Project deleted successfully.");
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Project deleted successfully.",
                  "success"
                );
                props.removeProject(id);
              } else {
                return res.json().then((json) => {
                  toast.error(json.message);
                });
              }
            })
            .catch((err) => {
              toast.error(err.message);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Action is cancelled. :)",
            "error"
          );
        }
      });
  };

  return (
    <div className="col-md-4 mb-4" key={id}>
      <div
        className="price-card text-center shadow project-card"
        style={{ borderRadius: "1.25rem" }}
      >
        <Tippy
          className="tippy-container"
          trigger="click"
          placement="bottom-end"
          arrowType="round"
          animation="scale"
          theme="light"
          distance="12"
          interactive="true"
          content={
            <nav>
              <Link to={"/projects/" + id + "/edit"}>
                <span className="menu-link">
                  <FontAwesomeIcon
                    className="fa-light fa-pen mr-2"
                    icon={faEdit}
                  />
                  <span>Edit</span>
                </span>
              </Link>
              <span
                onClick={() => handleDelete(id)}
                className="menu-link menu-link-danger"
              >
                <FontAwesomeIcon className="fa-light mr-2" icon={faTrash} />
                <span>Delete</span>
              </span>
            </nav>
          }
        >
          <h6
            className="text-nav dot-menu test-align-right"
            style={{ cursor: "pointer", textAlign: "right" }}
          >
            <FontAwesomeIcon className="fa-solid" icon={faEllipsisH} />
          </h6>
        </Tippy>

        <div className="card-body project-card-padding">
          <h6 className="project-text-ellipsis">{name}</h6>
          <p className="project-text-ellipsis">{description}</p>
        </div>
        <div className="row">
          <Link className="col-md-12" to={"/projects/" + id + "/versions"}>
            <Button
              variant="outline-primary"
              type="submit"
              className="shadow purple-button"
              style={{
                float: "inherit",
                height: "35px",
                fontWeight: "normal",
              }}
            >
              Versions →
            </Button>
          </Link>
          {props.project.enableAppReview ? (
            <Link
              className="col-md-12 mt-3 "
              to={"/projects/" + id + "/app-reviews"}
            >
              <Button
                variant="outline-primary"
                type="submit"
                className="shadow api-button"
                style={{
                  float: "inherit",
                  height: "35px",
                  fontWeight: "normal",
                }}
              >
                App Reviews →
              </Button>
            </Link>
          ) : (
            <></>
          )}
          <Link className="col-md-12 mt-3" to={"/projects/" + id + "/view"}>
            <Button
              variant="outline-primary"
              type="submit"
              className="shadow api-button"
              style={{
                float: "inherit",
                height: "35px",
                fontWeight: "normal",
                paddingBottom: "0px",
              }}
            >
              <span>API Key →</span>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectItem;
