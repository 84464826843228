import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const AppTooltip = (props) => {
  return (
    <OverlayTrigger
      placement={props.placement}
      overlay={<Tooltip>{props.overlay}</Tooltip>}
    >
      {props.children}
    </OverlayTrigger>
  );
};

export default AppTooltip;
