import React, { createContext, useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Constants from "../../Constants";
const AuthContext = createContext();

export const AuthProvider = (props) => {
  const auth = Auth();
  return (
    <AuthContext.Provider value={auth}> {props.children} </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const PrivateRoute = ({ children }) => {
  const auth = useAuth();
  auth.user = auth.getUserData();
  return auth.user ? children : <Navigate to="/signin" />;
};

export const PublicRoute = ({ children }) => {
  const auth = useAuth();
  auth.user = auth.getUserData();
  return auth.user ? children : children;
};

const Auth = () => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const user = getUserData();
    setUser(user);
  }, []);

  const signIn = (email, password, token) => {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email, password: password }),
    };

    let url = `${Constants.URL.SERVER_API_URL}/api/v1/auth/login`;

    if (token) {
      url = `${url}?token=${token}`;
    }

    return fetch(url, options)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return response.json().then((json) => {
            throw new Error(json.message);
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const signUp = (name, email, password, token) => {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ name: name, email: email, password: password }),
    };

    let url = `${Constants.URL.SERVER_API_URL}/api/v1/auth/register`;

    if (token) {
      url = `${url}?token=${token}`;
    }

    return fetch(url, options)
      .then((response) => {
        if (response.status === 201) {
          return response.json();
        } else {
          return response.json().then((json) => {
            throw new Error(json.message);
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const googleSignin = (accessToken, token) => {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ access_token: accessToken }),
    };

    let url = `${Constants.URL.SERVER_API_URL}/api/v1/auth/google`;

    if (token) {
      url = `${url}?token=${token}`;
    }

    return fetch(url, options)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return response.json().then((json) => {
            throw new Error(json.message);
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const signOut = () => {
    removeUserData();
  };

  const forgotPassword = (email) => {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
    };

    return fetch(
      `${Constants.URL.SERVER_API_URL}/api/v1/auth/forgot-password`,
      options
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return response.json().then((json) => {
            throw new Error(json.message);
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const resetPassword = (password, token) => {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password }),
    };

    return fetch(
      `${Constants.URL.SERVER_API_URL}/api/v1/auth/reset-password?token=${token}`,
      options
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return response.json().then((json) => {
            throw new Error(json.message);
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const setUserData = async (data) => {
    await Promise.resolve();
    localStorage.setItem("userId", data.user.id);
    localStorage.setItem("email", data.user.email);
    localStorage.setItem("name", data.user.name);
    localStorage.setItem("token", data.tokens.access.token);
    localStorage.setItem("createdAt", data.user.createdAt);
  };

  const getUserData = () => {
    const userId = localStorage.getItem("userId");
    const email = localStorage.getItem("email");
    const name = localStorage.getItem("name");
    const token = localStorage.getItem("token");
    const hasSubscription = localStorage.getItem("has_subscription");
    const createdAt = localStorage.getItem("createdAt");

    if (userId) {
      return {
        userId: userId,
        email: email,
        name: name,
        token: token,
        createdAt: createdAt,
        hasSubscription: hasSubscription,
      };
    } else {
      return null;
    }
  };

  const setUserSubscriptionInfo = (subscription) => {
    localStorage.setItem("has_subscription", subscription);
  };

  const removeUserData = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    localStorage.removeItem("token");
    localStorage.removeItem("has_subscription");
    localStorage.removeItem("hasSubscription");
    localStorage.removeItem("current_team_subscription");
    localStorage.removeItem("default_team_id");
    localStorage.removeItem("current_team");
    localStorage.removeItem("current_team_name");
    localStorage.removeItem("default_team_id");
    localStorage.removeItem("createdAt");
    localStorage.removeItem("force-payment");
    localStorage.removeItem("preferred_theme");
  };

  return {
    user,
    signIn,
    signUp,
    googleSignin,
    signOut,
    setUserData,
    getUserData,
    setUserSubscriptionInfo,
    removeUserData,
    forgotPassword,
    resetPassword,
  };
};

export default Auth;
