import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const AppIDFinder = () => {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm({ mode: "onChange" });
  const [appId, setAppId] = useState(null);
  const [error, setError] = useState(null);

  function extractAppIdFromStoreUrl(url) {
    // Define regular expression patterns for both Play Store and App Store URLs
    const playStoreUrlPattern =
      /^https:\/\/play\.google\.com\/store\/apps\/details\?id=([a-zA-Z0-9._%-]+)(?:&|$)/;
    const appStoreUrlPattern =
      /^https:\/\/apps\.apple\.com\/\w+\/app\/[^\/]+\/id(\d+)/;

    // Check if the URL matches either pattern and extract the app ID accordingly
    const playStoreMatch = url.match(playStoreUrlPattern);
    const appStoreMatch = url.match(appStoreUrlPattern);

    if (playStoreMatch && playStoreMatch[1]) {
      return playStoreMatch[1]; // Play Store app ID
    } else if (appStoreMatch && appStoreMatch[1]) {
      return appStoreMatch[1]; // App Store app ID
    } else {
      setError(
        "Invalid URL. Please enter valid Playstore or App Store url of your app."
      );
    }
  }

  const onSubmit = (data) => {
    setError(null);
    if (data.value) {
      const appId = extractAppIdFromStoreUrl(data.value);
      if (appId) {
        setAppId(appId);
      }
    }
  };

  const handleCopy = (data) => {
    navigator.clipboard.writeText(data);
    toast.success("Copied.");
  };

  const faqs = [
    {
      title: "What this App Id Finder do?",
      desc: "This tool extract the app ID from URLs of both Google Play Store and Apple App Store. It validates the input URL and returns the app ID if the URL is valid for either store.",
    },
    {
      title: "Can I use this to extract an app ID from a Play Store URL?",
      desc: "Yes, you can use this tool to get app Id by passing a Play Store URL in input box.",
    },
    {
      title: "Can I use this to extract an app ID from a Apple App Store URL?",
      desc: "Yes, you can use this tool to get app Id by passing a Apple App Store URL in input box.",
    },
    {
      title: "Where can I use the App Id?",
      desc: "You can use the returned App Id to pass as an argument value to appId for any of the App Upgrade SDK.",
    },
  ];

  return (
    <section className="landing-page-section bg-landing-page">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-xl-8 col-lg-8 col-md-8">
            <div className="section-title text-center title-ex1 mb-4">
              <div>
                <p>
                  <Link className="" to={"/utilities"}>
                    ← All Developer Utilities
                  </Link>{" "}
                </p>
                <h2 className="light-purple-gradient">App ID Finder</h2>
              </div>

              <p
                className="landing-muted-text"
                style={{ fontSize: "large", fontWeight: "normal" }}
              >
                Get the App ID of your app from playstore or appstore url.
              </p>
            </div>
          </div>

          <Container>
            <Row className="dark-mode justify-content-center mb-4">
              <Col lg={12} md={8} className="">
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                  <label className="text-cool-black mt-2">
                    *Playstore or App Store URL{" "}
                  </label>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <input
                        name="value"
                        className="form-control"
                        ref={register({ required: true })}
                        placeholder="https://play.google.com/store/apps/details?id=com.android.chrome"
                      />
                      {errors.message && (
                        <p className="error">Value is required</p>
                      )}
                      {error && <p className="error">{error}</p>}
                    </div>
                  </div>
                  <div className="d-flex mt-1">
                    <div className="form-group">
                      <button
                        className="btn btn-danger btn-block gradient-bg-accent"
                        type="submit"
                        disabled={!isDirty && !isValid}
                      >
                        {isSubmitting ? "Submit" : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
                <div className="form pt-0">
                  <label className="text-cool-black mt-4">Your App ID </label>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <input
                        name="value"
                        className="form-control"
                        defaultValue={appId}
                      />
                    </div>
                    <div className="">
                      <button
                        className="btn outline-text gradient-bg-accent"
                        onClick={() => handleCopy(appId)}
                        disabled={!appId}
                      >
                        <div className="d-flex align-items-center gap">
                          <FontAwesomeIcon className="fa-thin" icon={faCopy} />
                          <span class="pl-2">Copy to Clipboard</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              {faqs.map((faq, key) => (
                <Col className="mt-10" key={key} lg={6} md={6}>
                  <div style={{ textAlign: "left" }}>
                    <div className="d-flex justify-content-start">
                      <div
                        style={{ marginBottom: "0px", paddingRight: "4px" }}
                      ></div>
                      <h5 className="text-white">{faq.title}</h5>
                    </div>

                    <p
                      className="landing-muted-text mb-4"
                      style={{ fontSize: "1rem" }}
                    >
                      {faq.desc}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
            <Row className="justify-content-center mt-10 mb-4">
              <Col lg={12} md={8} className="">
                <div className="title text-center m-4">
                  <h1 className="font-weight-bolder line-height-1_4 text-white">
                    Easily{" "}
                    <span className="light-purple-gradient">force update</span>{" "}
                    your mobile apps.
                  </h1>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg={6} md={8}>
                <div className="title text-center mb-5">
                  <span
                    className="text-white text-landing-h1"
                    style={{ fontSize: "2.0rem" }}
                  >
                    Start with{" "}
                    <span className="light-purple-gradient">App Upgrade</span>,
                    today.
                  </span>
                  <p
                    className="light-purple-gradient mt-3"
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: "normal",
                      color: "rgba(144, 97, 249,1)",
                    }}
                  >
                    Start with <b>free 15 days</b> trial. No credit card
                    required.
                  </p>
                </div>
                <div
                  className="mt-5"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: "1.5rem",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <a
                      target="_blank"
                      href="https://appupgrade.dev/signup"
                      className="btn btn-cta btn-full-width outline-text glowing outline-shadow"
                      rel="noreferrer"
                    >
                      Get started →
                    </a>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <a
                      target="_blank"
                      href="mailto:support@appupgrade.dev"
                      className="btn btn-cta btn-full-width outline-text glowing outline-shadow"
                      rel="noreferrer"
                      style={{ padding: ".375rem .75rem" }}
                    >
                      Contact us →
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default AppIDFinder;
