import React, { useState, useEffect } from "react";
import "./VersionForm.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Constants from "../../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faPlus,
  faMinus,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Empty from "../Empty/Empty";
import AppTooltip from "../AppTooltip/AppTooltip";
import DocBlock from "../DocBlock/DocBlock";

const VersionForm = () => {
  const navigate = useNavigate();

  const [apiError, setApiError] = useState(null);
  const { register, handleSubmit, errors, setValue } = useForm();

  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [version, setVersion] = useState(null);
  const forcePayment = localStorage.getItem("force-payment");
  var [isVersionActive, setIsVersionActive] = useState(null);

  useEffect(() => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    fetch(
      `${Constants.URL.SERVER_API_URL}/api/v1/projects/${params.id}/versions/${
        params.versionId
      }?teamId=${localStorage.getItem("current_team")}`,
      options
    )
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          return res.json().then((version) => {
            if (version.customAttributes) {
              version.customAttributes = Object.entries(
                version.customAttributes
              ).map(([key, value]) => ({ key, value }));
            }
            setIsVersionActive(version.active);
            setVersion(version);
          });
        } else if (res.status >= 400 && res.status < 500) {
          return res.json().then((json) => {
            toast.error(json.message);
            setApiError(json.message);
          });
        } else {
          return res.json().then((json) => {
            throw new Error(json.message);
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
        setApiError("Uh oh!! something is not right!");
      });
  }, [params.id, params.versionId]);

  const onSubmit = (data) => {
    delete data.project;
    data.forceUpgrade =
      version.forceUpgrade === "Yes" || version.forceUpgrade === true
        ? true
        : false;

    if (version.customAttributes) {
      data.customAttributes = version.customAttributes.reduce(
        (result, { key, value }) => {
          if (key !== "" && value !== "") {
            result[key] = value;
          }
          return result;
        },
        {}
      );
    }

    data.messageLocale = version.messageLocale;
    data.active = isVersionActive;
    
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    };
    fetch(
      `${Constants.URL.SERVER_API_URL}/api/v1/projects/${
        version.project.id
      }/versions/${version.id}?teamId=${localStorage.getItem("current_team")}`,
      options
    )
      .then((res) => {
        if (res.status === 200) {
          toast("Version updated successfully.");
          navigate("/projects/" + params.id + "/versions");
        } else if (res.status >= 400 && res.status < 500) {
          return res.json().then((json) => {
            toast.error(json.message);
          });
        } else {
          return res.json().then((json) => {
            throw new Error(json.message);
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Uh oh!! something is not right!");
      });
  };

  const addMessageLocale = () => {
    version.messageLocale = [...version.messageLocale, { key: "", value: "" }];
    setVersion((version) => {
      return {
        ...version,
        messageLocale: version.messageLocale,
      };
    });
  };

  const removeMessageLocale = (i) => {
    let newMessageLocale = [...version.messageLocale];
    newMessageLocale.splice(i, 1);
    version.messageLocale = [...newMessageLocale];
    setVersion((version) => {
      return {
        ...version,
        messageLocale: version.messageLocale,
      };
    });
  };

  let handleChange = (i, e) => {
    let newMessageLocale = [...version.messageLocale];
    newMessageLocale[i][e.target.name] = e.target.value;
    version.messageLocale = [...newMessageLocale];
  };

  let generateMessage = () => {
    let messagesJson = require("./SampleMessageData.json");
    const random = Math.floor(Math.random() * messagesJson.length);
    setValue("message", messagesJson[random].message, { shouldValidate: true });
  };

  const addAttribute = () => {
    if (version.customAttributes) {
      version.customAttributes = [
        ...version.customAttributes,
        { key: "", value: "" },
      ];
    } else {
      version.customAttributes = [{ key: "", value: "" }];
    }

    setVersion((version) => {
      return {
        ...version,
        customAttributes: version.customAttributes,
      };
    });
  };

  const removeAttribute = (i) => {
    let newCustomAttributes = [...version.customAttributes];
    newCustomAttributes.splice(i, 1);
    version.customAttributes = [...newCustomAttributes];
    setVersion((version) => {
      return {
        ...version,
        customAttributes: version.customAttributes,
      };
    });
  };

  let handleAttributeChange = (i, e) => {
    let newCustomAttributes = [...version.customAttributes];
    newCustomAttributes[i][e.target.name] = e.target.value;
    version.customAttributes = [...newCustomAttributes];
  };

  let component;

  if (isLoading) {
    component = <Loader />;
  } else if (apiError) {
    component = <Empty text={apiError} />;
  } else if (!version) {
    component = <Empty text={apiError} />;
  } else {
    component = (
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className="form-row">
          <div className="form-group col-md-6">
            <input
              type="text"
              name="appName"
              autoFocus="autoFocus"
              className="form-control"
              ref={register({ required: true })}
              placeholder="*App Name - Name of your app."
              defaultValue={version.appName}
            />
            {errors.appName && (
              <span className="error">App name is required</span>
            )}
          </div>
          <div className="form-group col-md-6">
            <input
              name="appVersion"
              className="form-control"
              ref={register({ required: true })}
              placeholder="*App Version - Example: 1.0.0"
              defaultValue={version.appVersion}
            />
            {/* <span className="text-cool-gray-600" style={{ fontSize: "12px" }}>
              *The app version which was released and now you want users to
              update from.
            </span> */}
            {errors.appVersion && (
              <p className="error">App version is required</p>
            )}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-6">
            <input
              type="text"
              name="platform"
              className="form-control"
              ref={register({ required: true })}
              placeholder="*Platform - Example: android or ios"
              defaultValue={version.platform}
            />
            {errors.platform && (
              <span className="error">Platform is required</span>
            )}
          </div>
          <div className="form-group col-md-6">
            <input
              name="environment"
              className="form-control"
              ref={register({ required: true })}
              placeholder="*Environment - Example: development, staging, production."
              defaultValue={version.environment}
            />
            {errors.environment && (
              <span className="error">Environment is required</span>
            )}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-12">
            <textarea
              name="message"
              className="form-control"
              ref={register({ required: true })}
              placeholder="*Update Message - Message to show on update popup."
              defaultValue={version.message}
            />
            <span className="badge bg-purple" style={{ fontSize: "12px" }}>
              <span
                className="ai"
                style={{ cursor: "pointer" }}
                onClick={(e) => generateMessage()}
              >
                ✨Generate one{" "}
              </span>
              <span className="text-cool-gray-600">or try list of </span>
              <a
                target="_"
                href="https://appupgrade.dev/sample-app-update-messages"
              >
                Sample messages →
              </a>
            </span>
            {errors.message && <p className="error">Message is required</p>}
          </div>
        </div>

        <div className="mt-4 mb-2">
          <div id="accordion">
            <div className="">
              <div className="" id="headingOne">
                <h6 className="mb-0">
                  <a
                    className="btn-block underline-button text-cool-gray-600 pb-2"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    <FontAwesomeIcon
                      className="fa-solid mr-1"
                      icon={faChevronDown}
                    />{" "}
                    Advance Options
                  </a>
                </h6>
              </div>

              <div
                id="collapseOne"
                className="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div
                  className="bg-purple bg-purple-dark mt-4"
                  style={{ padding: "10px" }}
                >
                  <div className="form-group ml-2 version-info-box-2">
                    <div className="mt-2">
                      <label className="text-cool-black mt-1">
                        Message Localization
                        <span style={{ fontSize: "12px" }}>
                          <a
                            target="_blank"
                            href="https://appupgrade.dev/docs/message-localization"
                            className="text-sm"
                            rel="noreferrer"
                          >
                            {" "}
                            Learn More.
                          </a>
                        </span>
                      </label>

                      {version &&
                        version.messageLocale.map((a, index) => (
                          <div className="form-row" key={a.key + index}>
                            <div className="form-row col-md-12">
                              <div className="form-group col-md-3">
                                <input
                                  key="language-key"
                                  type="text"
                                  name="key"
                                  className="form-control"
                                  placeholder="*Language Key"
                                  defaultValue={a.key}
                                  onChange={(e) => handleChange(index, e)}
                                />
                              </div>
                              <div className="form-group col-md-8">
                                <textarea
                                  name="value"
                                  className="form-control"
                                  placeholder="*Localized Message"
                                  defaultValue={a.value}
                                  onChange={(e) => handleChange(index, e)}
                                />
                              </div>
                              <button
                                className="delete-button"
                                style={{ height: "40px" }}
                                onClick={(e) => removeMessageLocale(index)}
                                type="button"
                              >
                                <FontAwesomeIcon
                                  className="fa-solid"
                                  icon={faMinus}
                                />{" "}
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      <div className="button-section mt-2">
                        <button
                          className="text-cool-black"
                          style={{ height: "40px" }}
                          onClick={(e) => addMessageLocale(e)}
                          type="button"
                        >
                          <FontAwesomeIcon className="fa-solid" icon={faPlus} />{" "}
                          Add Localization
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <label className="mt-2"></label>
                <div
                  className="bg-purple bg-purple-dark"
                  style={{ padding: "10px" }}
                >
                  <div className="form-group ml-2 version-info-box-2">
                    <div className="mt-2">
                      <label className="text-cool-black mt-1">
                        Custom Attributes <span>[Beta]</span>
                        <span style={{ fontSize: "12px" }}>
                          <a
                            target="_blank"
                            href="https://appupgrade.dev/docs/app-upgrade-custom-attributes-feature"
                            className="text-sm"
                            rel="noreferrer"
                          >
                            {" "}
                            Learn More.
                          </a>
                        </span>
                      </label>

                      {version &&
                        version.customAttributes &&
                        version.customAttributes.map((a, index) => (
                          <div className="form-row" key={a.key + index}>
                            <div className="form-row col-md-12">
                              <div className="form-group col-md-3">
                                <input
                                  type="text"
                                  name="key"
                                  className="form-control"
                                  placeholder="Key"
                                  defaultValue={a.key}
                                  onChange={(e) =>
                                    handleAttributeChange(index, e)
                                  }
                                />
                              </div>
                              <div className="form-group col-md-3">
                                <input
                                  name="value"
                                  className="form-control"
                                  placeholder="Value"
                                  defaultValue={a.value}
                                  onChange={(e) =>
                                    handleAttributeChange(index, e)
                                  }
                                />
                              </div>
                              <button
                                className="delete-button"
                                style={{ height: "40px" }}
                                onClick={(e) => removeAttribute(index)}
                                type="button"
                              >
                                <FontAwesomeIcon
                                  className="fa-solid"
                                  icon={faMinus}
                                />{" "}
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      <div className="button-section mt-2">
                        <button
                          className="text-cool-black"
                          style={{ height: "40px" }}
                          onClick={(e) => addAttribute(e)}
                          type="button"
                        >
                          <FontAwesomeIcon className="fa-solid" icon={faPlus} />{" "}
                          Add Attribute
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-start align-items-center form-group">
          <div className="form-check mt-4 ml-1">
            <input
              name="checkbox"
              className="form-check-input shadow"
              type="checkbox"
              defaultChecked={version.forceUpgrade === "Yes" ? true : false}
              onClick={(e) => {
                version.forceUpgrade = e.target.checked;
              }}
            />
            <AppTooltip
              placement="right"
              overlay="if selected means this is going to be a force upgrade. If not selected indicates it’s not a force upgrade."
            >
              <label className="form-check-label vs-tooltip-content text-cool-black ml-2">
                Force Upgrade?
              </label>
            </AppTooltip>
          </div>
        </div>

        <div className="d-flex justify-content-start align-items-center form-group mt-4">
          <div className="form-group">
            <div className="d-flex">
              <span className="mr-2 text-cool-black">Status:</span>
              <label htmlFor={version.id} className="status-switch">
                <input
                  type="checkbox"
                  id={version.id}
                  checked={isVersionActive}
                  onChange={(e) => {
                    isVersionActive = !isVersionActive;
                    setIsVersionActive(isVersionActive);
                  }}
                />
                <span></span>
              </label>
            </div>
          </div>
        </div>

        <div className="form-row mt-4">
          <div className="form-group col-md-12">
            <textarea
              name="internalNotes"
              className="form-control"
              ref={register({ required: false })}
              placeholder="Internal Notes"
              defaultValue={version.internalNotes}
            />
          </div>
        </div>
        <div className="d-flex mt-3">
          <div className="form-group">
            <button
              className="btn btn-danger btn-block shadow gradient-bg-accent"
              type="submit"
            >
              Submit
            </button>
          </div>
          <div className="form-group">
            <Link to={"/projects/" + params.id + "/versions"}>
              <button className="cancel-button" type="submit">
                Cancel
              </button>
            </Link>
          </div>
        </div>

        {apiError && <span className="error">{apiError}</span>}
      </form>
    );
  }

  return (
    <section
      className="project-area my-5"
      style={
        forcePayment === "true"
          ? { filter: "blur(8px)", pointerEvents: "none" }
          : {}
      }
    >
      <div className="container">
        <div className="d-flex justify-content-between intro-container">
          <div className="version-heading">
            <div className="d-flex justify-content">
              <div>
                <p>
                  <Link className="gradient-text-accent" to={"/projects"}>
                    Projects
                  </Link>{" "}
                  <FontAwesomeIcon className="fa-solid" icon={faAngleRight} />{" "}
                  <Link
                    className="gradient-text-accent"
                    to={"/projects/" + params.id + "/versions"}
                  >
                    Versions{" "}
                  </Link>
                  <FontAwesomeIcon className="fa-solid" icon={faAngleRight} />{" "}
                  Edit
                </p>
              </div>
            </div>
            <span>Edit version of your app.</span>
          </div>
        </div>
        {component}
        <DocBlock></DocBlock>
      </div>
      <ToastContainer />
    </section>
  );
};

export default VersionForm;
