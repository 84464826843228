const DocBlock = () => {
  return (
    <div
      className="text-left"
      style={{ fontSize: "12px", padding: "30px 0 0 0" }}
    >
      <span className="">
        💡You can learn more about App Upgrade and how to integrate from our
      </span>
      <span>
        <a
          target="_blank"
          href="https://appupgrade.dev/docs/"
          className="delete-button gradient-text-accent font-weight-bold"
          rel="noreferrer"
        >
          {" "}
          Documentation
        </a>
      </span>{" "}
      <span className="text-sm">page.</span>
    </div>
  );
};

export default DocBlock;
