import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./Landing.css";
import Promo from "../../Images/Hero.png";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="landing-page-section bg-landing-page hero" id="hero">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} md={8}>
            <div className="title text-center mb-5 mx-auto">
              <div
                className="w-auto mb-4 outline-text reveal-effect-built"
                style={{ fontSize: "0.8rem" }}
              >
                <span>
                  🛠️{" "}
                  <span className="light-purple-gradient">
                    Built for Developers
                  </span>
                </span>
              </div>

              <h1 className="mb-4 font-weight-bolder line-height-1_4 text-white text-landing-hero reveal-effect-hero-text">
                Easily{" "}
                <span className="light-purple-gradient">force update</span> your
                mobile apps.
                <span className="text-primary font-weight-medium"></span>
              </h1>
              <p
                className="landing-muted-text mt-10 mb-4 pb-2 reveal-effect-hero-description"
                style={{ fontSize: "1.25rem" }}
              >
                Hey👋 app developers, now you can keep your app users up-to-date
                with the latest version effortlessly with App Upgrade.
              </p>
              <Link
                to="/signup"
                className="mt-2 py-2 px-4 btn btn-warning font-weight-bold gradient-bg-accent glowing reveal-effect-hero-cta"
              >
                Try App Upgrade today →
              </Link>
              <p
                className="light-purple-gradient mt-4 reveal-effect-hero-cta"
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "normal",
                  color: "rgba(144, 97, 249,1)",
                }}
              >
                Start with <b>free 15 days</b> trial. No credit card required.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
