import React from "react";
import "./NotFound.css";
import NotFound_SVG from "../../Images/not_found.svg";

const NotFound = () => {
  return (
    <section className="project-area my-5">
      <div className="container">
        <img
          className="col-md-4 col-sm-4 col-xs-4"
          src={NotFound_SVG}
          alt="Not Found"
        />
      </div>
    </section>
  );
};

export default NotFound;
