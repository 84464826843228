import React from "react";
import "./Checkout.css";
import "../Landing/Landing.css";
import { useState } from "react";
import { useEffect } from "react";
import Constants from "../../Constants";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Loader from "../Loader/Loader";
import { useAuth } from "../SignIn/UseAuth";
import SUCCESS_SVG from "../../Images/success.png";
import EMPTY_SVG from "../../Images/empty.svg";

const CheckSVG = () => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 16 12"
    fill="#8A8F98"
    className="sc-41395507-4 cmuLGQ"
  >
    <path d="M4.93942 9.69781L1.62578 6.38648C1.25336 6.01431 0.651746 6.01431 0.27932 6.38648C-0.0931065 6.75865 -0.0931065 7.35984 0.27932 7.73201L4.27096 11.7209C4.64339 12.093 5.245 12.093 5.61743 11.7209L15.7207 1.62465C16.0931 1.25248 16.0931 0.651292 15.7207 0.279125C15.3483 -0.0930417 14.7466 -0.0930417 14.3742 0.279125L4.93942 9.69781Z"></path>
  </svg>
);

const Checkout = (props) => {
  let [description, setDescription] = useState("");
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  const auth = useAuth();
  auth.user = auth.getUserData();
  const skip = props.skip !== undefined ? props.skip : true;

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage("Order Canceled.");
      setDescription("Continue to look around and checkout when you're ready.");
    }

    if (query.get("review")) {
      setSuccess(false);
      setMessage("Subscription Processing...");
      setDescription("We have received your request and processing it. Once it's processed your subscription will be activated and you will be notified.");
    }

    if (query.get("declined")) {
      setSuccess(false);
      setMessage("Order Declined.");
      setDescription("Order declined please try with different payment method.");
    }

    if (query.get("waiting")) {
      setSuccess(false);
      setMessage("Order Waiting.");
      setDescription("Continue to look around while we are waiting for subscription confirmation.");
    }
  }, []);

  if (!success && message === "") {
    return <Product skip={skip} />;
  } else if (success) {
    return <SuccessDisplay />;
  } else {
    return <Message message={message} description={description} />;
  }
};

const Product = (props) => {
  const auth = useAuth();
  auth.user = auth.getUserData();
  const { handleSubmit } = useForm();
  let [selectedMonthly, setSelectedMonthly] = useState(true);
  let [selectedYearly, setSelectedYearly] = useState(false);

  const starterMonthlySubscription = () => {
    subscription({ productPriceId: "price_1MirQfAqZHUrMqZIeA4Pso33" });
  };

  const starterYearlySubscription = () => {
    subscription({ productPriceId: "price_1MirSBAqZHUrMqZIbWke0AKm" });
  };

  const startupMonthlySubscription = () => {
    subscription({ productPriceId: "price_1Mir85AqZHUrMqZIOpDJ6DWN" });
  };

  const startupYearlySubscription = () => {
    subscription({ productPriceId: "price_1MirADAqZHUrMqZILviiJhwE" });
  };

  // Payproglobal
  const starterMonthlySubscriptionPayProglobal = () => {
    subscription({ productPriceId: "97013" });
  };

  const starterYearlySubscriptionPayProglobal = () => {
    subscription({ productPriceId: "97017" });
  };

  const startupMonthlySubscriptionPayProglobal = () => {
    subscription({ productPriceId: "97018" });
  };

  const startupYearlySubscriptionPayProglobal = () => {
    subscription({ productPriceId: "97019" });
  };

  const subscription = async (data) => {
    const productPriceId = data.productPriceId;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({ productPriceId }),
      redirect: "follow",
    };
    fetch(`${Constants.URL.SERVER_API_URL}/api/v1/checkout/create`, options)
      .then(async (res) => {
        // HTTP 301 redirect
        if (res.status === 200) {
          return res.json().then((json) => {
            window.location.href = json.url;
          });
        } else {
          return res.json().then((json) => {
            <Message message={json.message} />;
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <section
      className="landing-page-section bg-landing-page"
      style={{ paddingBottom: "30px!important" }}
      id="checkout"
    >
      <div className="radial-bg"></div>
      <div className="container reveal-effect-built mt-4">
        <div className="row justify-content-md-center">
          <div className="col-xl-6 col-lg-8 col-md-8">
            <div className="section-title text-center title-ex1">
              <h1 className="font-weight-bold text-dark">
                <span className="text-white text-landing-h1 light-purple-gradient">
                  Pricing
                </span>
              </h1>
              <p className="landing-muted-text" style={{ fontSize: "1.2rem" }}>
                Ready to see the benefits of seamless app updates for yourself?
                Join today and start your{" "}
                <b>
                  <span className="light-purple-gradient">
                    {" "}
                    <b>15 days free</b>{" "}
                  </span>
                </b>{" "}
                trial.
              </p>
              <div className="pricing-switch mb-4 mt-10 glowing">
                <button
                  id="checkout-and-portal-button-monthly-switch"
                  type="submit"
                  className={`btn btn-sm btn-full text-white ${selectedMonthly
                    ? `shadow checkout-active-button`
                    : `plain-button`
                    }`}
                  onClick={() => {
                    setSelectedMonthly(true);
                    setSelectedYearly(false);
                  }}
                >
                  Monthly
                </button>
                <button
                  id="checkout-and-portal-button-yearly-switch"
                  type="submit"
                  className={`btn btn-sm btn-full text-white ${selectedYearly
                    ? `shadow checkout-active-button`
                    : `plain-button`
                    }`}
                  onClick={() => {
                    setSelectedMonthly(false);
                    setSelectedYearly(true);
                  }}
                >
                  Yearly
                </button>
              </div>
              <p
                className=""
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "normal",
                  color: "#939db8",
                }}
              >
                🤫 Save 2 months on Yearly subscription.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-md-6 col-lg-4" style={{ paddingBottom: "15px" }}>
            <div className="price-card featured price-card-recommended price-card-top-padding">
              <h2 className="light-purple-gradient">Starter</h2>
              <span
                className="landing-muted-text"
                style={{ fontSize: "1rem" }}
              >
                Individuals and small teams.
              </span>
              {selectedMonthly ? (
                <p className="price text-white">
                  <span className="text-white">4.99</span>/ Month
                </p>
              ) : (
                <p className="price text-white">
                  <span className="text-white">49.99</span>/ Year
                </p>
              )}
              <ul
                className="pricing-offers landing-muted-text"
                style={{ fontSize: "1rem" }}
              >
                <li>
                  <CheckSVG /> 5 Projects
                </li>
                <li>
                  <CheckSVG /> Unlimited Versions
                </li>
                <li>
                  <CheckSVG /> Insights and Activity Logs
                </li>
                <li>
                  <CheckSVG /> Email Support
                </li>
              </ul>
              {auth.user ? (
                <form
                  onSubmit={handleSubmit(
                    selectedMonthly
                      ? starterMonthlySubscriptionPayProglobal
                      : starterYearlySubscriptionPayProglobal
                  )}
                >
                  {props.skip === true ? (
                    <Link to="/projects">
                      <button className=""></button>
                    </Link>
                  ) : (
                    <></>
                  )}
                  <button
                    id="checkout-and-portal-button-monthly"
                    type="submit"
                    className="btn btn-primary btn-mid gradient-bg-accent"
                  >
                    Start Now
                  </button>
                </form>
              ) : (
                <Link to="/signin">
                  <button
                    id="checkout-and-portal-button-monthly-signin"
                    type="submit"
                    className="btn btn-primary btn-mid gradient-bg-accent"
                  >
                    Start Now
                  </button>
                </Link>
              )}
            </div>
          </div>
          <div className="col-md-6 col-lg-4" style={{ paddingBottom: "15px" }}>
            <div className="price-card featured price-card-recommended price-card-top-padding">
              <h2 className="light-purple-gradient">Startups</h2>
              <span
                className="landing-muted-text"
                style={{ fontSize: "1rem" }}
              >
                Startups with more needs.
              </span>
              {selectedMonthly ? (
                <p className="price text-white">
                  <span className="text-white">7.99</span>/ Month
                </p>
              ) : (
                <p className="price text-white">
                  <span className="text-white">79.99</span>/ Year
                </p>
              )}
              <ul
                className="pricing-offers landing-muted-text"
                style={{ fontSize: "1rem" }}
              >
                <li>
                  <CheckSVG /> Everything in Starter, plus
                </li>
                <li>
                  <CheckSVG /> Unlimited Projects
                </li>
                <li>
                  <CheckSVG /> Unlimited Team Members
                </li>
                <li>
                  <CheckSVG /> Priority Email Support
                </li>
              </ul>
              {auth.user ? (
                <form
                  onSubmit={handleSubmit(
                    selectedMonthly
                      ? startupMonthlySubscriptionPayProglobal
                      : startupYearlySubscriptionPayProglobal
                  )}
                >
                  {props.skip === true ? (
                    <Link to="/projects">
                      <button className=""></button>
                    </Link>
                  ) : (
                    <></>
                  )}
                  <button
                    id="checkout-and-portal-button-yearly"
                    type="submit"
                    className="btn btn-primary btn-mid gradient-bg-accent ease-in-out"
                  >
                    Start Now
                  </button>
                </form>
              ) : (
                <Link to="/signin">
                  <button
                    id="checkout-and-portal-button-yearly-signin"
                    type="submit"
                    className="btn btn-primary btn-mid gradient-bg-accent"
                  >
                    Start Now
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="mt-2 d-md-block"
        style={{
          textAlign: "center",
          padding: " 10px 20px",
          zIndex: "1",
        }}
      >
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "1.6",
            letterSpacing: "-0.006px",
            verticalAlign: "text-top",
            display: "inline-block",
            margin: "0",
          }}
          className="landing-muted-text"
        >
          * Pricing is exclusive of taxes and additional local tax may be collected depending on your region.
        </p>
      </div>
    </section>
  );
};

const SuccessDisplay = () => {
  let [loading, setLoading] = useState(true);
  let [error, setError] = useState(false);

  let [message, setMessage] = useState(false);

  useEffect(() => {
    const wait = (delay) => {
      return new Promise((resolve) => setTimeout(resolve, delay));
    };

    const fetchRetry = (delay, tries = {}) => {
      function onError(err) {
        let triesLeft = tries - 1;
        if (triesLeft > 0) {
          return wait(delay).then(() => fetchRetry(delay, triesLeft));
        } else {
          setLoading(false);
          setError(true);
          setMessage(
            "Sorry, could not process the order! Check back later in sometime."
          );
        }
      }

      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      return fetch(`${Constants.URL.SERVER_API_URL}/api/v1/users/me`, options)
        .then(async (res) => {
          if (res.status === 200) {
            return res.json().then((json) => {
              if (json.hasSubscription) {
                localStorage.setItem("hasSubscription", true);
                setLoading(false);
              } else {
                throw new Error();
              }
            });
          } else {
            setLoading(true);
            setError(true);
            setMessage(
              "Sorry, could not process the order! Check back later in sometime."
            );
          }
        })
        .catch(onError);
    };

    fetchRetry(500, 5);
  }, []);

  const loadingScreen = <Loader />;

  const successScreen = (
    <section className="project-area my-5">
      <div className="container">
        <div className="product Box-root">
          <div className="description Box-root">
            <h3>Subscription to plan successful!</h3>
          </div>
        </div>
        <div style={{ padding: "15px" }}>
          <Link to={"/dashboard"}>
            <Button
              variant="primary"
              type="submit"
              className="new-project-button shadow gradient-bg-accent font-weight-bold"
            >
              Go to dashboard
            </Button>
          </Link>
        </div>
        <img
          className="col-md-4 col-sm-4 col-xs-4"
          src={SUCCESS_SVG}
          alt="Success Screen"
        />
      </div>
    </section>
  );

  return (
    <div>
      {loading ? (
        loadingScreen
      ) : error ? (
        <Message message={message} />
      ) : (
        successScreen
      )}
    </div>
  );
};

const Message = ({ message, description }) => (
  <section className="project-area">
    <div className="container">
      <img
        className="col-md-4 col-sm-4 col-xs-4"
        src={EMPTY_SVG}
        alt="Canceled Screen"
      />
      <h3>{message}</h3>
      <h6 className="pt-2">{description}</h6>
      <div style={{ padding: "15px" }}>
        <Link to={"/dashboard"}>
          <Button
            variant="primary"
            type="submit"
            className="new-project-button shadow gradient-bg-accent font-weight-bold"
          >
            Go to dashboard
          </Button>
        </Link>
      </div>

    </div>
  </section>
);

export default Checkout;
