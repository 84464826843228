import React, { useState, useEffect } from "react";
import "./ViewVersion.css";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Constants from "../../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Empty from "../Empty/Empty";
import SkeletonLoader from "../Skeleton/SkeletonLoader";
import AppTooltip from "../AppTooltip/AppTooltip";
import Android from "../../Images/android-logo-sdk.png";
import iOS from "../../Images/Apple_logo_black.svg.png";

const ViewVersion = (props) => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [version, setVersion] = useState(null);
  const [count, setCount] = useState(null);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    fetch(
      `${Constants.URL.SERVER_API_URL}/api/v1/projects/${params.id}/versions/${
        params.versionId
      }?teamId=${localStorage.getItem("current_team")}`,
      options
    )
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          return res.json().then((version) => {
            setVersion(version);
          });
        } else if (res.status >= 400 && res.status < 500) {
          return res.json().then((json) => {
            toast.error(json.message);
            setApiError(json.message);
          });
        } else {
          return res.json().then((json) => {
            throw new Error(json.message);
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
        setApiError("Uh oh!! something is not right!");
      });

    const statsoptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    fetch(
      `${Constants.URL.SERVER_API_URL}/api/v1/versions/stats/?vId=${params.versionId}`,
      statsoptions
    )
      .then((res) => {
        if (res.status === 200) {
          return res.json().then((response) => {
            setCount(response.count);
          });
        }
      })
      .catch((err) => {
        setApiError("Uh oh!! something is not right!");
      });
  }, [params.id, params.versionId]);

  let component;
  const skeleton = (
    <div className="col-md-12 view-version-box">
      <div className="version-info-box">
        <p>
          <b>
            <SkeletonLoader width={200} />
          </b>
        </p>
        <p>
          <b>
            <SkeletonLoader width={200} />
          </b>
        </p>
        <p>
          <b>
            <SkeletonLoader width={200} />
          </b>
        </p>
        <p>
          <b>
            <SkeletonLoader width={200} />
          </b>
        </p>
        <p>
          <b>
            <SkeletonLoader width={450} />
          </b>
        </p>
        <p>
          <b>
            <SkeletonLoader width={150} />
          </b>
        </p>
        <p>
          <b>
            <SkeletonLoader width={350} />
          </b>
        </p>
        <p>
          <b>
            <SkeletonLoader width={350} />
          </b>
        </p>
        <p>
          <b>
            <SkeletonLoader width={350} />
          </b>
        </p>
      </div>
    </div>
  );

  if (isLoading) {
    component = skeleton;
  } else if (apiError) {
    component = <Empty text={apiError} />;
  } else if (!version) {
    component = <Empty text={apiError} />;
  } else {
    component = (
      <div className="col-md-12 view-version-box">
        <div className="version-info-box">
          <p>
            <b>Project Name:</b> {version.project.name}
          </p>
          <p>
            <b>App Name:</b> {version.appName}
          </p>
          <p>
            <b>App Version:</b> {version.appVersion}
          </p>
          <p>
            <div className="d-flex justify-content: center">
              <b>Platform:</b>
              <div style={{ paddingLeft: "6px" }}>
                {version.platform === "android" ||
                version.platform === "Android" ? (
                  <div className="d-flex justify-content: center">
                    <img
                      className="platform-logo-android"
                      src={Android}
                      alt="android"
                    />{" "}
                    <p style={{ paddingLeft: "8px" }}>{version.platform}</p>
                  </div>
                ) : version.platform === "iOS" || version.platform === "ios" ? (
                  <div className="d-flex justify-content: center">
                    <img className="platform-logo-ios" src={iOS} alt="iOS" />{" "}
                    <p style={{ paddingLeft: "8px" }}>{version.platform}</p>
                  </div>
                ) : (
                  <div className="d-flex justify-content: center">
                    <p style={{ paddingLeft: "8px" }}>{version.platform}</p>
                  </div>
                )}
              </div>
            </div>
          </p>
          <p>
            <b>Environment:</b> {version.environment}
          </p>
          <p>
            <b>Default Update Message:</b> {version.message}
          </p>
          <p>
            <b>Localized Update Message(s):</b>
            {version.messageLocale && version.messageLocale.length > 0 ? (
              version.messageLocale.map((locale) => (
                <div className="bg-purple" style={{ padding: "12px" }}>
                  <b>{locale.key}</b>: {locale.value}
                </div>
              ))
            ) : (
              <> None</>
            )}
          </p>
          <p>
            <b>Force Upgrade:</b> {version.forceUpgrade}
          </p>
          <p>
            <b>Custom Attribute(s):</b>
            {version.customAttributes &&
            Object.keys(version.customAttributes).length > 0 ? (
              Object.keys(version.customAttributes).map((key) => (
                <div className="bg-purple" style={{ padding: "12px" }}>
                  <b>{key}</b>: {version.customAttributes[key]}
                </div>
              ))
            ) : (
              <> None</>
            )}
          </p>
          <p>
            <b>Internal Notes:</b> {version.internalNotes}
          </p>
          <p>
            <b>Status:</b> {version.active ? "Active" : "Inactive"}
          </p>
          <p>
            <b>Created By:</b> {version.createdBy.name} (
            {version.createdBy.email})
          </p>
          <p>
            <b>Created At:</b> {new Date(version.createdAt).toString()}
          </p>
          <p>
            <b>Last Updated At:</b> {new Date(version.updatedAt).toString()}
          </p>
          <p>
            <AppTooltip
              placement="top"
              overlay="Number of times this version is matched in last 14 days. This statistics feature is still in beta. The count shown here are calculated since the launch of the feature."
            >
              <span className="vs-tooltip-content">
                <b>Matched count in last 14 days:</b>{" "}
                {count !== null ? count : "N/A"}
              </span>
            </AppTooltip>
          </p>
        </div>
      </div>
    );
  }

  return (
    <section className="project-area my-5">
      <div className="container">
        <div className="d-flex justify-content-between intro-container">
          <div className="version-heading">
            <div className="d-flex justify-content">
              <div>
                <p>
                  <Link className="gradient-text-accent" to={"/projects"}>
                    Projects
                  </Link>{" "}
                  <FontAwesomeIcon className="fa-solid" icon={faAngleRight} />{" "}
                  <Link
                    className="gradient-text-accent"
                    to={"/projects/" + params.id + "/versions"}
                  >
                    Versions{" "}
                  </Link>
                  <FontAwesomeIcon className="fa-solid" icon={faAngleRight} />{" "}
                  Details
                </p>
              </div>
            </div>
            <span>Details of version.</span>
          </div>
        </div>
        {component}
      </div>
      <ToastContainer />
    </section>
  );
};

export default ViewVersion;
