import React, { useState } from "react";
import "./SignIn.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./UseAuth";
import { useSearchParams } from "react-router-dom";

const SignIn = () => {
  const navigate = useNavigate();
  const [apiError, setApiError] = useState(null);
  const { register, handleSubmit, watch, errors } = useForm();

  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const onSubmit = (data) => {
    auth
      .resetPassword(data.password, token)
      .then((data) => {
        setApiError(data.message);
      })
      .catch((err) => setApiError(err.message));
  };

  const resetPasswordForm = (
    <form onSubmit={handleSubmit(onSubmit)} className="py-5">
      <div className="form-group">
        <input
          type="password"
          name="password"
          className="form-control"
          ref={register({ required: true })}
          placeholder="Enter New Password"
        />
        {errors.password && <span className="error">Password is required</span>}
      </div>
      <div className="form-group">
        <input
          type="password"
          name="confirm_password"
          className="form-control"
          ref={register({
            validate: (value) => value === watch("password"),
          })}
          placeholder="Confirm New Password"
        />
        {errors.confirm_password && (
          <span className="error">Passwords don't match.</span>
        )}
      </div>
      <div className="form-group">
        <button className="btn btn-danger btn-block shadow gradient-bg-accent" type="submit">
          Submit
        </button>
      </div>

      {apiError && <span className="error">{apiError}</span>}

      <div className="option text-center">
        <label onClick={() => navigate("/signin")}>Sign in</label>
      </div>
    </form>
  );

  return (
    <div className="sign-up">
      <div className="container">
        <div className="logo text-center py-4"></div>
        {resetPasswordForm}
      </div>
    </div>
  );
};

export default SignIn;
