import React, { useEffect, useState, useRef, lazy, Suspense } from "react";
import Projects from "../Projects/Projects";
import DocBlock from "../DocBlock/DocBlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderOpen,
  faWaveSquare,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";

const Activity = lazy(() => import("../Activity/Activity"));
const Insight = lazy(() => import("../Insight/Insight"));
const InteractionLog = lazy(() => import("../Activity/InteractionLog"));

const Dashboard = () => {
  const [teamId, setTeamId] = useState(null);
  const forcePayment = localStorage.getItem("force-payment");

  useEffect(() => {
    setTeamId(localStorage.getItem("current_team"));
    const listenStorageChange = () => {
      setTeamId(localStorage.getItem("current_team"));
    };
    window.addEventListener("storage", listenStorageChange);
  }, []);

  return (
    <section
      className="project-area my-5"
      style={
        forcePayment === "true"
          ? { filter: "blur(8px)", pointerEvents: "none" }
          : {}
      }
    >
      <div className="container">
        {teamId ? (
          <>
            <nav>
              <div
                className="nav nav-tabs nav-dark"
                id="nav-tab"
                role="tablist"
              >
                <nav>
                  <div
                    className="nav nav-tabs nav-dark"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      className="nav-link active"
                      id="nav-project-details-tab"
                      data-toggle="tab"
                      data-target="#project-details"
                      type="button"
                      role="tab"
                      aria-controls="nav-project-details"
                      aria-selected="true"
                    >
                      <FontAwesomeIcon
                        className="fa-light mr-2"
                        icon={faFolderOpen}
                      />
                      Projects
                    </button>
                    <button
                      className="nav-link"
                      id="nav-activity-logs-tab"
                      data-toggle="tab"
                      data-target="#activity-logs"
                      type="button"
                      role="tab"
                      aria-controls="nav-activity-logs"
                      aria-selected="false"
                    >
                      <FontAwesomeIcon
                        className="fa-light mr-2"
                        icon={faWaveSquare}
                      />
                      Activity Logs
                    </button>
                    <button
                      className="nav-link"
                      id="nav-interaction-logs-tab"
                      data-toggle="tab"
                      data-target="#interaction-logs"
                      type="button"
                      role="tab"
                      aria-controls="nav-interaction-logs"
                      aria-selected="false"
                    >
                      <FontAwesomeIcon
                        className="fa-light mr-2"
                        icon={faWaveSquare}
                      />
                      Interaction Logs
                    </button>
                    <button
                      className="nav-link"
                      id="nav-insights-tab"
                      data-toggle="tab"
                      data-target="#insights"
                      type="button"
                      role="tab"
                      aria-controls="nav-insights"
                      aria-selected="false"
                    >
                      <FontAwesomeIcon
                        className="fa-light mr-2"
                        icon={faChartLine}
                      />
                      Insights
                    </button>
                  </div>
                </nav>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="project-details"
                role="tabpanel"
                aria-labelledby="nav-project-details-tab"
              >
                {/* Content for Projects tab */}
                <Projects teamId={teamId} />
              </div>
              <div
                className="tab-pane fade"
                id="activity-logs"
                role="tabpanel"
                aria-labelledby="nav-activity-logs-tab"
              >
                {/* Content for Activity Logs tab */}
                <Suspense fallback={null}>
                  <ActivityWithLazyLoad teamId={teamId} />
                </Suspense>
              </div>
              <div
                className="tab-pane fade"
                id="interaction-logs"
                role="tabpanel"
                aria-labelledby="nav-interaction-logs-tab"
              >
                {/* Content for Interaction Logs tab */}
                <Suspense fallback={null}>
                  <InteractionLogWithLazyLoad teamId={teamId} />
                </Suspense>
              </div>
              <div
                className="tab-pane fade"
                id="insights"
                role="tabpanel"
                aria-labelledby="nav-insights-tab"
              >
                {/* Content for Insights tab */}
                <Suspense fallback={null}>
                  <InsightWithLazyLoad teamId={teamId} />
                </Suspense>
              </div>
            </div>

            <DocBlock></DocBlock>
          </>
        ) : (
          <></>
        )}
      </div>
    </section>
  );
};

// Wrapping the lazy-loaded components with IntersectionObserver
const ActivityWithLazyLoad = ({ teamId }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return isVisible ? <Activity teamId={teamId} /> : <div ref={ref}></div>;
};

const InteractionLogWithLazyLoad = ({ teamId }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return isVisible ? <InteractionLog teamId={teamId} /> : <div ref={ref}></div>;
};

const InsightWithLazyLoad = ({ teamId }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return isVisible ? <Insight teamId={teamId} /> : <div ref={ref}></div>;
};

export default Dashboard;
